import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Clock } from "@c1/gravity-icons/dist/svg/ui-filled-clock-1-24.svg";
import shallow from "zustand/shallow";
import { addNonCriticalEventToApplication } from "../../../api/sbaoAppEndpoints";
import useStore from "../../../store/store";
import Button from "../Button";
import { Col, Row } from "../Layout";
import usePersistentTimeout from "./usePersistentTimeout";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const twentyFiveMinuteInterval = 1000 * 60 * 25;

const nonTimeoutLocations = [
  "/decision/approved",
  "/decision/declined",
  "/session-ended",
  "/timeout",
  "/something-went-wrong",
  "/restart-application",
];

const useModalContent = () => {
  const content = usePreSubmitTreatment("preSubmitFriction", "applicationSessionTimeoutWarning");
  if (content) {
    return window.UI_ENV === "prod"
      ? {
          headerText: content.headerTextProd,
          bodyText: content.bodyTextProd,
          endButtonText: content.endButtonTextProd,
          continueButtonText: content.continueButtonTextProd,
        }
      : {
          headerText: content.headerTextNonProd,
          bodyText: content.bodyTextNonProd,
          endButtonText: content.endButtonTextNonProd,
          continueButtonText: content.continueButtonTextNonProd,
        };
  }
  return {
    headerText: "Your session is about to expire",
    bodyText: "For your security, your session will expire in",
    endButtonText: "End Session",
    continueButtonText: "Continue",
  };
};

const getTimeoutConfigs = state => {
  const configs = state.content.find(bundle => bundle.configs)?.configs;
  const timeToWarn = configs?.find(obj => obj.label === "sessionTimeoutLength")?.value;
  const timeAfterWarn = configs?.find(obj => obj.label === "sessionTimeoutWarningLength")?.value;
  return [parseInt(timeToWarn, 10), parseInt(timeAfterWarn, 10)];
};

// pulled directly from v1 - could potentially be replaced by a fancier date formatter later
const convertMSToTimeString = ms => {
  const numberToString = num => (num < 10 ? `0${num}` : `${num}`);

  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor(totalSeconds / 60) - hours * 60;
  const seconds = totalSeconds - minutes * 60 - hours * 3600;

  return [hours, minutes, seconds].map(numberToString).join(":");
};

const Heading = styled.h1.attrs({
  className: "grv-dialog__heading",
  id: "timeout-dialog-header",
})`
  flex: 1;
  margin-bottom: 0;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
`;

const DialogContainer = styled.div.attrs({
  className: "grv-dialog__container grv-padding--large-1",
  id: "timeout-modal",
})`
  padding-top: 60px;
  /* specific sizing since grv-dialog--large has no effect in gravity-core */
  max-width: 760px;
`;

const ClockIcon = styled(Clock).attrs({
  className: "grv-padding--small-2",
})`
  height: 50px;
  aspect-ratio: 1;
`;

const Description = styled.span.attrs({
  id: "timeout-dialog-desc",
})`
  padding-left: 10px;
`;

const TimeoutModal = () => {
  // lookup timer configuration and activate timer if it is enabled for this page
  const [timeToWarn, timeAfterWarn] = useStore(state => getTimeoutConfigs(state), shallow);
  const modalContent = useModalContent();
  const location = useLocation();
  const enabledForLocation = !nonTimeoutLocations.includes(location.pathname);
  const timeout = usePersistentTimeout(timeToWarn, timeAfterWarn, enabledForLocation);

  // also lookup application reference ID since that will be needed for events
  const applicationReferenceId = useStore(state => state.applicationReferenceId);

  const navigate = useNavigate();

  // when the warning activates, add the timeoutPopped event to the application
  useEffect(() => {
    if (applicationReferenceId && timeout.warn) {
      // no need to await - this can happen asynchronously
      addNonCriticalEventToApplication({ applicationReferenceId, event: "timeoutPopped" });
    }
  }, [timeout.warn, applicationReferenceId]);

  // update ui db on a routine interval, ensuring sbao-app doesn't invalidate the application: https://jira.kdc.capitalone.com/browse/SBBTDAO-4665
  const [shouldHeartbeat, setShouldHeartbeat] = useState(true);
  useEffect(() => {
    if (applicationReferenceId && shouldHeartbeat) {
      const interval = setInterval(async () => {
        try {
          await addNonCriticalEventToApplication({
            applicationReferenceId,
            event: "heartbeat",
            propagateErrors: true,
          });
        } catch (_) {
          // if we fully fail to send a heartbeat (4 failed requests in a row), give up
          setShouldHeartbeat(false);
          // go ahead and clear the interval immediately too, the cleanup from this effect will become a no-op
          clearInterval(interval);
        }
      }, twentyFiveMinuteInterval);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [applicationReferenceId, shouldHeartbeat]);

  // when the timer expires, add the sessionExpired event to the application and navigate away
  useEffect(() => {
    if (timeout.expired) {
      if (applicationReferenceId) {
        // no need to await - this can happen asynchronously
        addNonCriticalEventToApplication({ applicationReferenceId, event: "sessionExpired" });
      }
      navigate("/timeout");
    }
  }, [timeout.expired, applicationReferenceId, navigate]);

  // called when the user manually ends the session, adds the sessionEnded event and navigates away
  const onSessionEnded = useCallback(() => {
    if (applicationReferenceId) {
      // no need to await - this can happen asynchronously
      addNonCriticalEventToApplication({ applicationReferenceId, event: "sessionEnded" });
    }
    navigate("/session-ended");
  }, [applicationReferenceId, navigate]);

  return (
    <ReactModal
      ariaHideApp
      preventScroll
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      aria={{
        labelledby: "timeout-dialog-header",
        describedby: "timeout-dialog-desc",
      }}
      bodyOpenClassName={null}
      className="grv-dialog grv-dialog--active"
      contentLabel={modalContent.headerText}
      htmlOpenClassName={null}
      id="timeout-portal"
      isOpen={timeout.warn && !timeout.expired && enabledForLocation}
      // timeout-overlay class is used in root index.jsx to set overlay z-index
      overlayClassName="grv-dialog__overlay grv-dialog__overlay--dark timeout-overlay"
      portalClassName="grv-portal"
      role="dialog"
      shouldCloseOnEsc={false}
    >
      <DialogContainer>
        <div className="grv-dialog__content" id="timeout-dialog-content">
          <Row>
            <Col lg={6} md={6} sm={4}>
              <HeadingWrapper>
                <ClockIcon />
                <Heading>{modalContent.headerText}</Heading>
              </HeadingWrapper>
            </Col>
          </Row>
          <Row className="grv-padding__top--medium-2">
            <Col lg={6} md={6} sm={4}>
              <Description>
                {modalContent.bodyText}{" "}
                <span className="grv-weight--semibold">{convertMSToTimeString(timeout.timeRemaining)}</span>
              </Description>
            </Col>
          </Row>
        </div>
        <div className="grv-dialog__buttons">
          <Button id={useButtonId("modal", "endSession")} gravityType="text" onClick={onSessionEnded}>
            {modalContent.endButtonText}
          </Button>
          <Button id={useButtonId("modal", "continueSession")} gravityType="action" onClick={timeout.reset}>
            {modalContent.continueButtonText}
          </Button>
        </div>
      </DialogContainer>
    </ReactModal>
  );
};

export default TimeoutModal;
