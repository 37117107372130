import ExplanationModal from "./ExplanationModal";

const TaxpayerIdentificationModal = ({ active, onOk }) => (
  <ExplanationModal
    {...{ active, onOk }}
    idBase="taxPayerIdentification"
    headingText="Social Security/Taxpayer ID Number"
    className="grv-dialog--normal"
    shouldCloseOnEsc
    shouldCloseOnOverlayClick
  >
    <p className="grv-text__heading--normal grv-weight--normal grv-margin__bottom--medium-1">
      A Taxpayer Identification Number (TIN) is an identification number used by the Internal Revenue Service
      (IRS). This is the number commonly used to file your tax return.
    </p>
    <p className="grv-text__heading--normal grv-weight--normal grv-margin__bottom--medium-1">
      If you are an individual, your TIN is usually your Social Security Number (SSN), which is issued by the
      Social Security Administration (SSA). If you don’t qualify for an SSN, you may have an Individual
      Taxpayer Identification Number (ITIN), which is issued by the IRS.
    </p>
    <p className="grv-text__heading--normal grv-weight--normal grv-margin__bottom--medium-1">
      If you are a business entity or an estate, your TIN is usually your Employer Identification Number
      (EIN), which is issued by the IRS.
    </p>
  </ExplanationModal>
);

export default TaxpayerIdentificationModal;
