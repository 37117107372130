import { useNavigate } from "react-router-dom";
import Page from "../../shared/Page";
import Heading from "../../shared/Heading";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import sessionExpiredIcon from "../../../assets/session-expired.svg";
import sessionEndedIcon from "../../../assets/caution.svg";
import getContentReader from "../../../utils/getContentReader";
import Button from "../../shared/Button";
import ExternalLink from "../../shared/ExternalLink";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import { useButtonId, useLinkId } from "../../../utils/hooks/usePageScopedId";
import Icon from "../../shared/Icon/Icon";

const Timeout = ({ icon, contentBundleId }) => {
  usePageTitle("Timeout");
  const content = usePreSubmitTreatment("preSubmitFriction", contentBundleId);

  const navigate = useNavigate();
  const handleRestartApplicationButtonClick = () => {
    navigate("/product-selection");
    window.location.reload();
  };
  const buttonId = useButtonId("restartApplication");
  const linkId = useLinkId("capitaloneHomepage");

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(content);

  return (
    <Page hideVoiceNumber>
      <Grid>
        <CenteredRow>
          <Col lg={8}>
            <Icon src={icon} $large className="grv-margin__bottom--medium-2" />
          </Col>
        </CenteredRow>
        <Heading
          mainHeading={readContent("headerText")}
          subHeading={readContent("bodyText")}
          extraSpacingAfterMain
        />
        <CenteredRow>
          <Col lg={8}>
            <Button
              id={buttonId}
              className="grv-button--action grv-margin__top--normal"
              onClick={handleRestartApplicationButtonClick}
            >
              {readContent("buttonText")}
            </Button>
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col lg={8} className="grv-margin__top--normal">
            <ExternalLink id={linkId} href={readContent("linkTarget")}>
              {readContent("linkText")}
            </ExternalLink>
          </Col>
        </CenteredRow>
      </Grid>
    </Page>
  );
};

export const SessionEnded = () => (
  <Timeout icon={sessionEndedIcon} contentBundleId="applicationSessionEnded" />
);

export const SessionExpired = () => (
  <Timeout icon={sessionExpiredIcon} contentBundleId="applicationSessionTimeout" />
);
