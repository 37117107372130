import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import shallow from "zustand/shallow";
import { SOLE_PROPRIETORSHIP } from "../../../constants/legalEntityTypes";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import useStore from "../../../store/store";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import DisabledField from "../../shared/DisabledField";
import Heading from "../../shared/Heading";
import { CenteredRow, Col, Grid, Row } from "../../shared/Layout";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import NavigationButtons from "../../shared/NavigationButtons";
import Page from "../../shared/Page";
import SectionHeader from "../../shared/SectionHeader";
import TextInput from "../../shared/TextInput";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import { useNavigateUnlessReview } from "../../../utils/reviewContext";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useRouting from "../../../utils/hooks/useRouting";
import AccountPurposeFields, {
  accountPurposeSchema,
  useDefaultAccountPurpose,
} from "../../shared/AccountPurposeFields";
import doingBusinessNameSchema from "../../../utils/doingBusinessNameSchema";
import AnnualRevenueRangeSelect, { annualRevenueRangeSchema } from "../../shared/AnnualRevenueSelect";
import CharitableOrganizationSection, {
  conditionalCharitableOrganizationSchema,
  useDefaultCharitableOrganizationInfo,
} from "../../shared/CharitableOrganizationSection";
import BusinessOutsideUSSection, {
  conditionalBusinessOutsideUSSchema,
  useDefaultOutsideUSInfo,
} from "../../shared/BusinessOutsideUSSection";
import BusinessIndustryCombobox, {
  businessIndustrySchema,
  useIsIndustryListLoaded,
} from "../../shared/BusinessIndustryCombobox";
import { useInputId } from "../../../utils/hooks/usePageScopedId";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import useProductIdMapping from "../../../utils/hooks/useProductIdMapping";
import { PROMO_AND_PRODUCT_VALIDATION } from "../../../constants/promoValidationTypes";
import { SBB_WEB } from "../../../constants/applicationChannelTypes";
import { processPromoValidation } from "../../../utils/promoCodeValidation";
import PromoCodeIssueModal from "../../shared/PromoCodeIssueModal";
import { isFeatureEnabled } from "../../../utils/configSelector";

const detailSchema = yup.object().shape({
  doingBusinessName: doingBusinessNameSchema,
  businessIndustry: businessIndustrySchema,
  annualRevenueRange: annualRevenueRangeSchema,
  accountPurpose: accountPurposeSchema,
  ...conditionalBusinessOutsideUSSchema,
  ...conditionalCharitableOrganizationSchema,
});

const DoingBusinessName = ({ register, errors }) => (
  <TextInput
    id={useInputId("doingBusinessName")}
    label="Doing Business As (DBA) Name (If Applicable)"
    helper="Enter your public-facing name. This will appear on any checks you order."
    error={errors?.doingBusinessName?.message}
    maxLength={36}
    {...register("doingBusinessName")}
  />
);

const BusinessDetails = () => {
  usePageTitle("Business Details");
  usePreventNavigation();
  const headingRef = useFocusHeading();
  const [
    isSoleProp,
    legalBusinessName,
    storedDoingBusinessAsName,
    storedIndustry,
    storedAnnualRevenueRange,
    industryCodeLookup,
    storedApplicationProductsSelected,
    storedPromotionCode,
    isApplicantPrefilled,
    offerFulfillmentEnabled,
    setDisplayPromotionCode,
    setPromotionMessageDetails,
    submitBusinessDetails,
  ] = useStore(
    state => [
      state.businessLegalEntityType === SOLE_PROPRIETORSHIP,
      `${state.applicantFirstName} ${state.applicantLastName}`,
      state.businessDoingBusinessAsName,
      state.businessIndustryDescription,
      state.businessAnnualRevenueRange,
      state.industryContent.industryCodeLookup,
      state.applicationProductsSelected,
      state.promotionCode,
      state.isApplicantPrefilled,
      isFeatureEnabled(state, "offerFulfillmentEnabled"),
      state.setDisplayPromotionCode,
      state.setPromotionMessageDetails,
      state.pageSubmit.submitBusinessDetails,
    ],
    shallow
  );

  const accountPurposeDefault = useDefaultAccountPurpose();
  const charitableOrgDefault = useDefaultCharitableOrganizationInfo();
  const outsideUSDefault = useDefaultOutsideUSInfo();

  const applicationChannel = useApplicationChannel();
  const promoCodeEnabled = offerFulfillmentEnabled && applicationChannel === SBB_WEB;
  const [promotionCodeUnavailable, setPromotionCodeUnavailable] = useState(false);
  const [promotionCodeExpired, setPromotionCodeExpired] = useState(false);
  const productIdMapping = useProductIdMapping();

  const formMethods = useForm({
    resolver: yupResolver(detailSchema),
    context: { industryCodeLookup },
    shouldFocusError: false,
    defaultValues: {
      doingBusinessName: storedDoingBusinessAsName || "",
      businessIndustry: storedIndustry || "",
      annualRevenueRange: storedAnnualRevenueRange || PLACEHOLDER_NONE,
      accountPurpose: accountPurposeDefault,
      ...outsideUSDefault,
      ...charitableOrgDefault,
    },
  });

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = formMethods;

  const navigate = useNavigateUnlessReview();
  const [backRoute, nextRoute] = useRouting();

  const onSubmit = useCallback(
    async data => {
      const {
        doingBusinessName,
        businessIndustry,
        annualRevenueRange,
        accountPurpose,
        isBusinessHeadquarteredOutsideUS,
        isCharitableOrganization,
        outsideUSInfo,
        charitableOrganization,
        skipPromoCheck = false,
      } = data;

      // prefilled applicants w/ new businesses have their promo code checked on ExistingBusinessSelection page, so do not need to check here
      if (!skipPromoCheck && !isApplicantPrefilled && promoCodeEnabled && storedPromotionCode) {
        try {
          // first get the product associated with the active promo code
          const getProductPromoValidated = storedApplicationProductsSelected.find(
            product =>
              product.promotionCodeDetails?.promotionCode &&
              product.promotionCodeDetails?.passedValidations.includes(PROMO_AND_PRODUCT_VALIDATION)
          );

          // if the product was not found for some reason, then we should show an error and stop displaying the banner
          if (!getProductPromoValidated) {
            setPromotionCodeUnavailable(true);
            setDisplayPromotionCode(false);
            return;
          }

          const {
            productType,
            promotionCodeDetails: { promotionCode },
          } = getProductPromoValidated;
          // next, call the promo validation API for the product in order to get the fundingPromotionMessage
          const { isPromotionValid, errorCode, fundingPromotionMessage } = await processPromoValidation(
            productIdMapping[productType],
            productType,
            promotionCode
          );
          // we need to re-set this here in case patient is an authenticated NEW CUSTOMER
          setPromotionMessageDetails(productType, fundingPromotionMessage);
          // if the promo is not valid, process it accordingly
          if (!isPromotionValid) {
            setDisplayPromotionCode(false);
            switch (errorCode) {
              case "228007":
                setPromotionCodeExpired(true);
                return;
              default:
                setPromotionCodeUnavailable(true);
                return;
            }
          }
        } catch (error) {
          setPromotionCodeUnavailable(true);
          setDisplayPromotionCode(false);
          return;
        }
      }

      // now that promo is handled, proceed with the rest of the form submission
      const naicsCode = industryCodeLookup.get(businessIndustry);
      await submitBusinessDetails({
        doingBusinessName,
        businessIndustry,
        naicsCode,
        annualRevenueRange,
        accountPurpose,
        isBusinessHeadquarteredOutsideUS,
        isCharitableOrganization,
        outsideUSInfo,
        charitableOrganization,
      });
      navigate(nextRoute);
    },
    [
      submitBusinessDetails,
      industryCodeLookup,
      navigate,
      nextRoute,
      promoCodeEnabled,
      storedPromotionCode,
      storedApplicationProductsSelected,
      productIdMapping,
      setDisplayPromotionCode,
      setPromotionMessageDetails,
      isApplicantPrefilled,
    ]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(onSubmit);

  const onContinue = async () => {
    const formData = getValues();
    const dataWithSkipPromoCheck = {
      ...formData,
      skipPromoCheck: true,
    };
    await submitWithErrorHandling(dataWithSkipPromoCheck);
  };

  const industryListLoaded = useIsIndustryListLoaded();
  if (!industryListLoaded) {
    return <LoadingSpinnerPage />;
  }

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step="SECTION 2 OF 4"
          mainHeading="Tell us about your business"
          subHeading="To create your application, we need some details about your business."
        />
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            <SectionHeader title="Business details" />
          </Col>
        </CenteredRow>
        {isSoleProp && (
          <Row>
            <Col lg={5} md={4} offset={{ lg: 2 }} sm={4}>
              <DisabledField
                helper="Since your company is a Sole Proprietorship, we filled this in with the legal first and last name you entered earlier"
                label="Legal Business Name"
                value={legalBusinessName}
              />
            </Col>
          </Row>
        )}
      </Grid>
      <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
        <Grid>
          <Row>
            <Col lg={5} md={4} offset={{ lg: 2 }} sm={4}>
              <DoingBusinessName {...{ register, errors }} />
            </Col>
          </Row>
          <CenteredRow>
            <Col lg={8} md={8} sm={4}>
              <BusinessIndustryCombobox control={control} />
            </Col>
          </CenteredRow>
          <Row>
            <Col lg={4} md={3} offset={{ lg: 2 }} sm={4}>
              <AnnualRevenueRangeSelect
                label="Annual Business Revenue"
                helper="This refers to the gross income in 1 year"
                error={errors?.annualRevenueRange?.message}
                {...register("annualRevenueRange")}
              />
            </Col>
          </Row>
        </Grid>
        <FormProvider {...formMethods}>
          <Grid>
            <AccountPurposeFields />
          </Grid>
          <BusinessOutsideUSSection />
          <CharitableOrganizationSection />
        </FormProvider>
        <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
      </form>
      {promoCodeEnabled && (
        // Modal will be enabled if the code is unavailable or if there was an error looking up the code
        // The only difference between the two states is some messaging
        <PromoCodeIssueModal
          isOpen={promotionCodeUnavailable || promotionCodeExpired}
          onClose={() => {
            setPromotionCodeUnavailable(false);
            setPromotionCodeExpired(false);
          }}
          onContinue={onContinue}
          idBase={promotionCodeUnavailable ? "promoCodeUnavailable" : "promoCodeExpired"}
          headingText={promotionCodeUnavailable ? "Promo unavailable" : "Promo expired"}
          showBackButton={!promotionCodeExpired}
        >
          {promotionCodeUnavailable ? (
            <span>
              The promo code <span className="grv-weight--semibold">{storedPromotionCode}</span> is
              unavailable for this business. Click “Back” to modify your account type(s) or click “Continue”
              to continue your application without a promo code.
            </span>
          ) : (
            <span>
              The promo code <span className="grv-weight--semibold">{storedPromotionCode}</span> has expired.
              Click “Continue” to continue your application without a promo code.
            </span>
          )}
        </PromoCodeIssueModal>
      )}
    </Page>
  );
};

export default BusinessDetails;
