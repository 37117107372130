import { useEffect } from "react";
import settingsImage from "../../../assets/settings.svg";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import getContentReader from "../../../utils/getContentReader";
import useStore from "../../../store/store";
import TerminalPageTemplate from "../../shared/TerminalPageTemplate";
import { addNonCriticalEventToApplication } from "../../../api/sbaoAppEndpoints";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import { SBB_WEB } from "../../../constants/applicationChannelTypes";
import ApplicationUnavailable from "../ApplicationUnavailable";

const NonRestartableError = () => {
  usePageTitle("Something Went Wrong");
  const applicationReferenceId = useStore(state => state.applicationReferenceId);
  const region = useStore(state => state.applicantAddressRegion);
  const applicationChannel = useApplicationChannel();

  const showNonRestartableErrors = region === "InFoot" || applicationChannel !== SBB_WEB;
  const content = usePreSubmitTreatment("preSubmitFriction", "nonRestartableErrors");

  useEffect(() => {
    if (applicationReferenceId) {
      // no need to await - this can happen asynchronously
      addNonCriticalEventToApplication({ applicationReferenceId, event: "nonRestartableErrorPageViewed" });
    }
  }, [applicationReferenceId]);

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  return showNonRestartableErrors ? (
    <TerminalPageTemplate
      enableButton
      buttonId="nonRestartableError"
      buttonClassName=""
      image={settingsImage}
      mediumIcon
      readContent={getContentReader(content)}
      extraSpacingAfterMainHeading={false}
    />
  ) : (
    // webAppUnavailable will only show if the user is both out of foot and on the web channel
    <ApplicationUnavailable />
  );
};

export default NonRestartableError;
