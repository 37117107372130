import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import { BooleanRadioListField } from "../../shared/RadioList";
import SectionHeader from "../../shared/SectionHeader";
import CompanyTypeRadios from "../../shared/CompanyTypeRadios";

const CompanyTypeRadioList = styled(CompanyTypeRadios).attrs({
  errorReplacesHelper: false,
  className: "grv-margin__bottom--none",
})`
  /* Make legend into flex column so error stacks under helper text */
  & > legend {
    display: flex;
    flex-direction: column;
  }

  /* Better than passing error classes since this maintains the Gravity form classes on the error element */
  & > legend > [role="alert"] {
    margin-top: var(--grv-size-spacing-medium-2);
  }
`;

const ExistingNonProfitCompanyTypeSection = () => {
  const { control, resetField, watch, setValue } = useFormContext();

  const areStateDocsFiled = watch("areStateDocsFiled");
  const hasMultipleBusinessOwners = watch("hasMultipleBusinessOwners");
  const displayCompanyTypeSection =
    areStateDocsFiled !== null &&
    hasMultipleBusinessOwners !== null &&
    (areStateDocsFiled === true || hasMultipleBusinessOwners === true);

  return (
    <Grid>
      <CenteredRow>
        <Col lg={8} md={8} sm={4}>
          <SectionHeader title="Company type identification" />
        </Col>
      </CenteredRow>
      <CenteredRow>
        <Col lg={8} md={8} sm={4}>
          <BooleanRadioListField
            control={control}
            name="areStateDocsFiled"
            legendText="Did you or someone on your behalf file documents with the state to establish your business (e.g., Articles of Organization, Certificate of Formation, Articles of Incorporation, Corporate Charter)?"
            onChange={() => {
              setValue("companyTypeSelected", "");
            }}
          />
        </Col>
      </CenteredRow>
      <CenteredRow>
        <Col lg={8} md={8} sm={4}>
          <BooleanRadioListField
            control={control}
            name="hasMultipleBusinessOwners"
            legendText="Does your business have multiple business owners?"
            onChange={() => {
              setValue("companyTypeSelected", "");
            }}
          />
        </Col>
      </CenteredRow>
      {displayCompanyTypeSection && (
        <>
          <CenteredRow>
            <Col lg={8} md={8} sm={4}>
              <SectionHeader compact title="Additional business details" />
            </Col>
          </CenteredRow>
          <CenteredRow>
            <Col lg={8} md={8} sm={4}>
              <Controller
                control={control}
                name="companyTypeSelected"
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <CompanyTypeRadioList
                    {...{ areStateDocsFiled, hasMultipleBusinessOwners }}
                    isKnownNonProfit
                    error={error?.message}
                    onChange={event => {
                      // reset tax classification and partnership structure to clear old values
                      // this should prevent invalid combinations of values from switching between company types
                      resetField("partnershipStructure");
                      // defaultValue set manually for taxClassification to avoid issues of navigating back causing
                      // the dropdown to get set to whatever field was selected before, even if the company type has changed
                      resetField("taxClassification", { defaultValue: PLACEHOLDER_NONE });
                      // then finally call onChange normally
                      onChange(event);
                    }}
                    {...rest}
                  />
                )}
              />
            </Col>
          </CenteredRow>
        </>
      )}
    </Grid>
  );
};

export default ExistingNonProfitCompanyTypeSection;
