import styled from "styled-components";
import mediaQuery from "../../../../utils/mediaQuery";
import { CenteredRow, Col, Grid, Wrapper } from "../../../shared/Layout";
import combineStrings from "../../../../utils/combineStrings";
import Footnotes from "../../../shared/Footnotes";

const Container = styled.div`
  /* Add bottom margin on larger screens */
  @media (${mediaQuery.medium}) {
    margin-bottom: var(--grv-size-spacing-large-2);
  }

  /* force any links that come from Chariot content to inherit these styles */
  a {
    color: inherit;
  }
`;

// This does assume that the promo code information is always displayed *before* the secondary product information,
// if both are present. If that ever changes, this will need to be reworked.
const DisclosureFootnotesSection = ({
  className = "",
  displayPromotionCodeInformation,
  displayLimitedEligibilityPromotionCodeInformation,
  mobileDepositsExplanation,
}) => {
  if (!displayPromotionCodeInformation && !mobileDepositsExplanation) {
    // If there are no footnotes, return an empty fragment to avoid creating extra spacing
    // Moving this logic outside of the component would create duplication of these checks
    return <></>;
  }

  return (
    <Container
      className={combineStrings(
        "grv-text--small-1",
        "grv-padding__top--none",
        "grv-color--digital-gray-70",
        className
      )}
    >
      <Wrapper>
        <Grid>
          <CenteredRow>
            <Col lg={12} className="grv-padding__left--none grv-padding__right--none">
              <Footnotes>
                {displayPromotionCodeInformation && (
                  <li id="promoCodeInfo">
                    {displayLimitedEligibilityPromotionCodeInformation &&
                      "Only customers already having a Capital One Business credit card for the last 90 days will be eligible for the offer. "}
                    Activities required to be completed on your checking account in order to be eligible for
                    the cash bonus of $600 are:
                    <ul className="grv-padding__left--medium-3 grv-padding__right--none grv-margin__top--none grv-margin__bottom--none">
                      <li className="grv-padding__left--small-2">
                        Within 30 days of account opening, deposit at least $10,000 from non-Capital One
                        accounts. Transfers between Capital One accounts will not qualify
                      </li>
                      <li className="grv-padding__left--small-2">
                        Maintain a minimum end of day balance of $10,000 for at least 60 days within 90 days
                        of account opening
                      </li>
                      <li className="grv-padding__left--small-2">
                        Make 10 qualifying electronic transactions within 90 days of account opening.
                        Qualifying transactions include electronic wire (credits or debits), electronic ACH
                        (credits or debits), and remote check deposit
                      </li>
                    </ul>
                  </li>
                )}
                {mobileDepositsExplanation && (
                  <li
                    id="productSecondaryInfo"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: mobileDepositsExplanation }}
                  />
                )}
              </Footnotes>
            </Col>
          </CenteredRow>
        </Grid>
      </Wrapper>
    </Container>
  );
};

export default DisclosureFootnotesSection;
