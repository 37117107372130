import { forwardRef } from "react";
import styled, { css } from "styled-components";
import { Grid } from "../Layout";

const DEFAULT_SECTION_ID = "footnoteSectionLabel";
const DEFAULT_REF_COUNTER = "footnote-links";
const DEFAULT_NOTE_COUNTER = "footnotes";

// This implementation of footnotes is based on links within the page with a specific aria-describedby attribute.
// This is loosely based on https://www.sitepoint.com/accessible-footnotes-css/
// and https://www.joshwcomeau.com/css/styling-ordered-lists-with-css-counters/

// IF YOU HAVE ONLY ONE FOOTNOTE SECTION ON A GIVEN PAGE
// You can ignore the majority of these docs below. These components will use reasonable defaults.
// Use FootnoteReference components to create the links, and embed the footnoteReferenceContainerCSS
// somewhere above them all in the component tree, likely at either the Page or form level.
// Then, put the Footnotes component at the bottom of the page.

// IF YOU NEED MULTIPLE FOOTNOTE SECTIONS ON A GIVEN PAGE
// First, consider refactoring.
// If you really can't, you can override the various controls in these components as follows:
// Footnote numbers on a given page are controlled via two counters and links with a specific aria-describedby attribute.
// The aria-describedby attribute
// The first counter is used to number the footnote links on the page and is set by the $referenceCounterName
// prop on footnoteReferenceContainerCSS.
// The second counter is used to number the footnote entries in the actual footnote list and is set by the $counterName
// prop on FootnotesList.
// You will not be able to use the Footnotes component below, and will need to create a new component using FootnotesList
// and creating its own footnotes header, which the FootnoteReferences can point to in their aria-describedby attribute.

// This CSS needs to be embedded in whatever component contains the actual text that will have footnotes.
// This should be done as high up the element hierarchy as is reasonable, as it should be embedded once and
// contain every footnote reference on the page.
// Note that this implementation REQUIRES footnotes to appear in the same order as their references on the page.
export const footnoteReferenceContainerCSS = css`
  /* Establish a counter for each footnote link */
  counter-reset: ${({ $referenceCounterName = DEFAULT_REF_COUNTER }) => $referenceCounterName};

  /* Hide link styling on each footnote link and increment the counter */
  a[aria-describedby="${({ $footnoteSectionId = DEFAULT_SECTION_ID }) => $footnoteSectionId}"] {
    counter-increment: ${({ $referenceCounterName = DEFAULT_REF_COUNTER }) => $referenceCounterName};
    text-decoration: none;
    color: inherit;
    cursor: default;
    outline: none;
  }

  /* Display a superscript number after each footnote link */
  a[aria-describedby="${({ $footnoteSectionId = DEFAULT_SECTION_ID }) => $footnoteSectionId}"]::after {
    content: counter(${({ $referenceCounterName = DEFAULT_REF_COUNTER }) => $referenceCounterName});
    vertical-align: super;
    padding-left: 0.125ch;
    font-size: 0.625em; /* 10px for the 16px standard text, but scales for the larger promo code details text */
  }
`;

// Utility component for when all footnotes are within the same containing Grid.
export const GridWithFootnotes = styled(Grid)`
  ${footnoteReferenceContainerCSS}
`;

// Creates a footnote reference link, with the link pointing to relevant entry in the footnote section.
// Props are generally forwarded to the underlying anchor, but the "to" prop is required and should match
// the id of the li element in the footnotes section (either the Footnotes or FootnotesList component children).
// The aria-describedby prop is set to the default id of the footnote section, if you need to override this it can
// be done with the aria-describedby prop.
export const FootnoteReference = forwardRef(({ to, children, ...rest }, ref) => (
  <a href={`#${to}`} aria-describedby={DEFAULT_SECTION_ID} {...rest} ref={ref}>
    {children}
  </a>
));

// Creates a FootnoteReference with a specific footnote number.
// IMPORTANT: This will affect the number of ALL subsequent footnotes on the page! Use with caution.
export const FootnoteReferenceWithSpecificNumber = styled(FootnoteReference)`
  counter-set: ${DEFAULT_REF_COUNTER} ${({ $number }) => $number};
`;

// This should only be used directly if you need a custom footnote section.
// Otherwise, use the Footnotes component below.
// $counterName prop controls the name of the footnote CSS counter.
export const FootnotesList = styled.ol`
  /* Disable default list styling, use our own counter so we can style it */
  list-style: none;
  counter-reset: ${({ $counterName = DEFAULT_NOTE_COUNTER }) => $counterName};

  /* Remove all spacing that comes from gravity */
  padding-inline: 0;
  margin-block: 0;

  & > li {
    counter-increment: ${({ $counterName = DEFAULT_NOTE_COUNTER }) => $counterName};

    /* 
      Precede each footnote with a superscript number from the counter.
      Note this assumes the order will be the same as the order of the links on the page!
    */
    &:before {
      content: counter(footnotes);
      padding-right: 2ch;
    }
  }

  /* Add padding between the list items */
  & > * + li {
    padding-top: var(--grv-size-spacing-medium-1);
  }
`;

// Children of this component should be li elements.
const Footnotes = ({ children }) => (
  <>
    <h3 id={DEFAULT_SECTION_ID} hidden>
      Footnotes
    </h3>
    <FootnotesList>{children}</FootnotesList>
  </>
);

export default Footnotes;
