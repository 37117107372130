import styled from "styled-components";
import ExternalLink from "../ExternalLink";

const links = [
  {
    href: "https://www.capitalone.com/privacy",
    id: "privacyStyledAnchor",
    text: "Privacy",
  },
  {
    href: "https://www.capitalone.com/applications/identity-protection/commitment",
    id: "securityStyledAnchor",
    text: "Security",
  },
  {
    href: "https://www.capitalone.com/privacy/online-privacy-policy#collecting-using-sharing-information-accordion-button-5",
    id: "adChoicesStyledAnchor",
    text: "AdChoices",
  },
  {
    href: "https://www.capitalone.com/legal/terms-conditions",
    id: "termsConditionsStyledAnchor",
    text: "Terms & Conditions",
  },
];

const FooterLinksContainer = styled.ul.attrs({
  className: "grv-text--small-1 grv-margin--none grv-padding--none",
})`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  height: 100%;
  gap: var(--grv-size-spacing-small-2);

  /* Every list item but the first needs a visual separator on the left */
  & > li:not(:first-child) {
    border-left: 1px solid var(--grv-color-digital-gray-10);
    padding-left: var(--grv-size-spacing-small-2);
  }

  & a {
    text-decoration: none;
  }
`;

const FooterLinks = () => (
  <FooterLinksContainer>
    {links.map(({ href, id, text }) => (
      <li key={id}>
        <ExternalLink
          href={href}
          id={id}
          title="Link opens in a new window"
          className="grv-color--digital-gray-60"
        >
          {text}
        </ExternalLink>
      </li>
    ))}
  </FooterLinksContainer>
);

export default FooterLinks;
