import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCallback, useState, useEffect, forwardRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import shallow from "zustand/shallow";
import getContentReader from "../../../utils/getContentReader";
import getUiDisclosureContent from "../../../utils/getUiDisclosureContent";
import getComplianceDisclosureContent from "../../../utils/getComplianceDisclosureContent";
import { splitChariotTextLink, splitChariotTextHtml } from "../../../utils/parseChariotItem";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import useStore from "../../../store/store";
import Checkbox from "../../shared/Checkbox";
import Heading from "../../shared/Heading";
import { CenteredRow, Col, Grid, Row } from "../../shared/Layout";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import NavigationButtons from "../../shared/NavigationButtons";
import Page from "../../shared/Page";
import TextInput from "../../shared/TextInput";
import { BooleanRadioListField } from "../../shared/RadioList";
import SectionHeader from "../../shared/SectionHeader";
import { SOLE_PROPRIETORSHIP } from "../../../constants/legalEntityTypes";
import BackupWithholdingModal from "./BackupWithholdingModal";
import TaxpayerIdentificationModal from "./TaxpayerIdentificationModal";
import UsPersonModal from "./UsPersonModal";
import FactaReportingModal from "./FactaReportingModal";
import {
  APPROVED,
  APPROVED_AND_ACCEPTED,
  APPROVED_AND_BOOKED,
  APPROVED_AND_BOOKING_ERROR,
  DECLINED,
  ERROR_BACKEND_SYSTEM,
  ERROR_DECISIONING_DATA,
  ERROR_DECISIONING_SYSTEM,
  ERROR_WILL_RETRY_DECISIONING,
  REVIEW,
  GOVID_SMS,
  GOVID_EMAIL,
  SUBMITTED_WITH_ERRORS,
} from "../../../constants/submitApplicationAndAccountStatuses";
import { useBackRoute } from "../../../utils/hooks/useRouting";
import { addNonCriticalEventToApplication } from "../../../api/sbaoAppEndpoints";
import {
  EXISTING_CUSTOMER_BLOCK,
  EXISTING_CUSTOMER_SIGN_IN,
  EXISTING_CUSTOMER_SSO_ENROLL,
} from "../../../constants/eligibilityCodes";
import { sendErrorToNewRelic } from "../../../utils/newRelic";
import { isFeatureEnabled } from "../../../utils/configSelector";
import { useCollectDeviceData } from "../../../utils/hooks/useCollectDeviceData";
import { useButtonId, useCheckboxId, useLinkId, useInputId } from "../../../utils/hooks/usePageScopedId";
import { convertProductTypeEnumToQueryParam } from "../../../constants/productTypes";

const CheckboxWithPaddedHelper = styled(Checkbox)`
  & .grv-form-field__helper--error {
    padding-left: var(--grv-size-spacing-large-2);
  }
`;

const CertificationCheckbox = forwardRef(({ name, ...rest }, ref) => (
  <CheckboxWithPaddedHelper id={useCheckboxId(name)} name={name} ref={ref} {...rest} />
));

// Specific padding to align the list under the checkbox - TODO consider revising layout
const DisclosureList = styled.ul.attrs({
  className: "grv-list__list grv-list__list--small grv-color--interaction-blue-50",
})`
  padding-left: 50px;
`;

// Not using the ExternalLink component here since most of the attributes are not wanted
const DisclosureModalLink = styled.a.attrs({
  role: "button",
  tabIndex: "0",
})`
  background: transparent;
  text-decoration: underline;
  color: var(--grv-color-icon-interaction-blue-50);
  cursor: pointer;
`;

const DisclosureLink = styled(DisclosureModalLink).attrs({
  "aria-label": "", // setting the empty label is the correct accessibility behavior for links with text content
  target: "_blank",
  rel: "noopener noreferrer",
  role: "link",
})``;

const CertificationStatementBox = styled.div.attrs({
  className: "grv-padding--medium-2 grv-margin__bottom--large-3",
})`
  border: var(--grv-size-border-width-1) solid #000000;
`;

// Specific padding to align the list under the checkbox - TODO consider revising layout
const CertificationStatementList = styled.ol.attrs({
  className: "grv-list__list grv-list__list--small grv-weight--semibold",
})`
  padding-left: 50px;
`;

const SubmitInProgressOverlay = () => {
  useEffect(() => {
    // Prevent scrolling when overlay is on top
    document.body.style.overflow = "hidden";
    // Upon unmount, reallow scrolling
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);
  return (
    <div className="grv-spinner grv-spinner--dark grv-spinner--fullscreen grv-spinner--active">
      <div className="grv-spinner__description grv-text--medium-3" aria-atomic="true" role="alert">
        Submitting your application...
      </div>
    </div>
  );
};

const disclosuresSchema = yup.object().shape({
  hasReadAndAgreedTS: yup
    .bool()
    .isTrue("Certify that you have read and agreed to the following terms and conditions")
    .required("Certify that you have read and agreed to the following terms and conditions"),
  hasAuthorizedObtainCreditBureauInfo: yup
    .bool()
    .isTrue("Certify that you authorize Capital One to obtain credit bureau information under your name")
    .required("Certify that you authorize Capital One to obtain credit bureau information under your name"),
  hasCertifiedAccountResponsibility: yup
    .bool()
    .isTrue("Certify that you are the person who will be responsible for the account")
    .required("Certify that you are the person who will be responsible for the account"),
  taxExemptPayeeCode: yup.number().when("isTaxExempt", {
    is: true,
    then: schema =>
      schema
        .typeError("Enter your tax exempt payee code")
        .required("Enter your tax exempt payee code")
        .min(1, "Enter a valid exempt payee code between 1 and 13")
        .max(13, "Enter a valid exempt payee code between 1 and 13"),
    otherwise: () => yup.mixed(),
  }),
  penaltiesOfPerjury: yup
    .bool()
    .isTrue("Certify that the following statements are accurate and true")
    .required("Certify that the following statements are accurate and true"),
});

const DisclosureLinks = ({ rgdaContent, eddnContent, factaContent }) => (
  <DisclosureList>
    <li key={rgdaContent.disclosureId}>
      <DisclosureLink id={useLinkId("rgdaDisclosure")} href={rgdaContent.disclosureUrl}>
        Rules Governing Deposit Accounts, Electronic Fund Transfer Agreement and Disclosure for Personal and
        Commercial Accounts, and Wire Funds Transfer Disclosure Statement
      </DisclosureLink>
    </li>
    <li key={eddnContent.disclosureId}>
      <DisclosureLink id={useLinkId("eddnDisclosure")} href={eddnContent.disclosureUrl}>
        Electronic Disclosures Agreement
      </DisclosureLink>
    </li>
    <li key={factaContent.disclosureId}>
      <DisclosureLink id={useLinkId("factaDisclosure")} href={factaContent.disclosureUrl}>
        Fair Credit Reporting Act (FCRA) and Fair and Accurate Credit Transactions Act (FACTA) Notice
      </DisclosureLink>
    </li>
  </DisclosureList>
);

const BackupWithholding = ({ control, readContent, yesLabel, noLabel }) => {
  const [showModal, setShowModal] = useState(false);
  const onClickLink = () => setShowModal(true);

  const [questionPartOne, questionPartTwo, questionPartThree] = splitChariotTextLink(readContent);

  return (
    <Row>
      <Col lg={8} md={5} offset={{ lg: 2 }} sm={4}>
        <BooleanRadioListField
          control={control}
          name="isSubjectToBackupWithholding"
          legendText={
            <>
              {questionPartOne}
              <DisclosureModalLink
                id={useLinkId("backupWithholdingDisclosure")}
                onClick={onClickLink}
                onKeyDown={event => event.key === "Enter" && onClickLink()}
              >
                {questionPartTwo}
              </DisclosureModalLink>
              {questionPartThree}
            </>
          }
          yesLabel={yesLabel}
          noLabel={noLabel}
        />
        <BackupWithholdingModal active={showModal} onOk={() => setShowModal(false)} />
      </Col>
    </Row>
  );
};

const TaxExemptPayeeCode = ({ register, errors }) => (
  <TextInput
    id={useInputId("taxExemptPayeeCode")}
    label="Exempt Payee Code"
    helper={
      <>
        Enter the number that you would write in
        <b> Box 4 </b>
        of your W-9 form. Reference the
        <DisclosureLink id={useLinkId("w9Instructions")} href="https://www.irs.gov/pub/irs-pdf/fw9.pdf">
          {" "}
          W-9{" "}
        </DisclosureLink>
        instructions for a list of exempt payee codes.
      </>
    }
    error={errors?.taxExemptPayeeCode?.message}
    {...register("taxExemptPayeeCode")}
  />
);

const PenaltiesOfPerjury = ({
  register,
  errors,
  w9Attestation,
  readW9AttestationLine1,
  w9AttestationLine2,
  readW9AttestationLine3,
  readW9AttestationLine4,
  w9AttestationIrs,
}) => {
  const [showTaxPayerModal, setShowTaxpayerModal] = useState(false);
  const taxPayerModalLinkId = useLinkId("taxpayerDisclosure");

  const [showUSPersonModal, setShowUSPersonModal] = useState(false);
  const usPersonModalLinkId = useLinkId("taxpayerDisclosure");

  const [showFATCAModal, setShowFATCAModal] = useState(false);
  const fatcaModalLinkId = useLinkId("fatcaModal");

  const w9AttestationLine1 = splitChariotTextLink(readW9AttestationLine1);
  const w9AttestationLine3 = splitChariotTextLink(readW9AttestationLine3);
  const w9AttestationLine4 = splitChariotTextLink(readW9AttestationLine4);

  return (
    <CertificationStatementBox>
      <CertificationCheckbox
        label={w9Attestation}
        error={errors?.penaltiesOfPerjury?.message}
        bold
        {...register("penaltiesOfPerjury")}
      />
      <CertificationStatementList>
        <li>
          {w9AttestationLine1[0]}
          <DisclosureModalLink
            id={taxPayerModalLinkId}
            onClick={() => setShowTaxpayerModal(true)}
            onKeyDown={event => event.key === "Enter" && setShowTaxpayerModal(true)}
          >
            {w9AttestationLine1[1]}
          </DisclosureModalLink>
          {w9AttestationLine1[2]}
        </li>
        <TaxpayerIdentificationModal active={showTaxPayerModal} onOk={() => setShowTaxpayerModal(false)} />
        <li>{w9AttestationLine2}</li>
        <li>
          {w9AttestationLine3[0]}
          <DisclosureModalLink
            id={usPersonModalLinkId}
            onClick={() => setShowUSPersonModal(true)}
            onKeyDown={event => event.key === "Enter" && setShowUSPersonModal(true)}
          >
            {w9AttestationLine3[1]}
          </DisclosureModalLink>
          {w9AttestationLine3[2]}
        </li>
        <UsPersonModal active={showUSPersonModal} onOk={() => setShowUSPersonModal(false)} />
        <li>
          {w9AttestationLine4[0]}
          <DisclosureModalLink
            id={fatcaModalLinkId}
            onClick={() => setShowFATCAModal(true)}
            onKeyDown={event => event.key === "Enter" && setShowFATCAModal(true)}
          >
            {w9AttestationLine4[1]}
          </DisclosureModalLink>
          {w9AttestationLine4[2]}
        </li>
        <FactaReportingModal active={showFATCAModal} onOk={() => setShowFATCAModal(false)} />
      </CertificationStatementList>
      <div className="grv-text--small-2 grv-weight--semibold grv-margin__left--large-1">
        <i>{w9AttestationIrs}</i>
      </div>
    </CertificationStatementBox>
  );
};

// Converts the list of selected products to a query param that is used in the URI for some routes
// These params are solely used to provide the marketing team with data
// in order to optimize our Google Ads
const createProductParamList = applicationProductsSelected => {
  const queryParamsForSelectedProducts = applicationProductsSelected.map(selectedProduct =>
    convertProductTypeEnumToQueryParam(selectedProduct.productType)
  );

  // combine products into single string, sort for consistency
  return queryParamsForSelectedProducts.filter(Boolean).sort().join("-");
};

const TermsAndConditions = () => {
  usePageTitle("Terms & conditions");
  useCollectDeviceData();
  const headingRef = useFocusHeading();

  const [
    applicationReferenceId,
    submitTermsAndConditionsAndApp,
    storedBusinessLegalEntityType,
    storedIsSubjectToBackupWithholding,
    storedIsTaxExempt,
    storedTaxExemptPayeeCode,
    isSingleMemberLLC,
    applicationProductsSelected,
    backupWithholdingQuestionContent,
    backupWithholdingYesContent,
    backupWithholdingNoContent,
    taxExemptionQuestionContent,
    singleMemberLLCTaxExemptionQuestionContent,
    rgdaContent,
    eddnContent,
    factaContent,
    w9AttestationContent,
    w9AttestationLine1Content,
    w9AttestationLine2YesContent,
    w9AttestationLine2NoContent,
    w9AttestationLine3Content,
    w9AttestationLine4Content,
    w9AttestationIrsContent,
    govIdSelfieEnabled,
  ] = useStore(
    state => [
      state.applicationReferenceId,
      state.pageSubmit.submitTermsAndConditionsAndApp,
      state.businessLegalEntityType,
      state.isSubjectToBackupWithholding,
      state.isTaxExempt,
      state.taxExemptPayeeCode,
      state.isSingleMemberLLC,
      state.applicationProductsSelected,
      getUiDisclosureContent(state, "backupWithholdingQuestion"),
      getUiDisclosureContent(state, "backupWithholdingYes"),
      getUiDisclosureContent(state, "backupWithholdingNo"),
      getUiDisclosureContent(state, "taxExemption"),
      getUiDisclosureContent(state, "singleMemberLLCTaxExemption"),
      getComplianceDisclosureContent(state, "sbao.comp.disclosure.rgda"),
      getComplianceDisclosureContent(state, "sbao.comp.disclosure.eddn"),
      getComplianceDisclosureContent(state, "sbao.comp.disclosure.facta"),
      getUiDisclosureContent(state, "w9Attestation"),
      getUiDisclosureContent(state, "w9AttestationLine1"),
      getUiDisclosureContent(state, "w9AttestationLine2Yes"),
      getUiDisclosureContent(state, "w9AttestationLine2No"),
      getUiDisclosureContent(state, "w9AttestationLine3"),
      getUiDisclosureContent(state, "w9AttestationLine4"),
      getUiDisclosureContent(state, "w9AttestationIrs"),
      isFeatureEnabled(state, "govIdSelfieEnabled"),
    ],
    shallow
  );

  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(disclosuresSchema),
    shouldFocusError: false,
    defaultValues: {
      businessLegalEntityType: storedBusinessLegalEntityType,
      hasAttestedToTermsAndConditions: false,
      hasReadAndAgreedTS: false,
      hasAuthorizedObtainCreditBureauInfo: false,
      hasCertifiedAccountResponsibility: false,
      isSubjectToBackupWithholding: storedIsSubjectToBackupWithholding || false,
      isTaxExempt: storedIsTaxExempt || false,
      taxExemptPayeeCode: storedTaxExemptPayeeCode || null,
    },
  });

  const isSubjectToBackupWithholdingWatch = watch("isSubjectToBackupWithholding");
  const isTaxExemptWatch = watch("isTaxExempt");
  const navigate = useNavigate();
  const backRoute = useBackRoute();
  // NOTE: We do not want to use the useSubmitWithErrorHandling hook here because any failures on submit should not be retriable
  const submitHandler = useCallback(
    async ({ isSubjectToBackupWithholding, isTaxExempt, taxExemptPayeeCode }) => {
      try {
        const { applicationStatus = "" } = await submitTermsAndConditionsAndApp({
          isSubjectToBackupWithholding,
          isTaxExempt,
          taxExemptPayeeCode,
        });

        const navigateWithProductParam = route => {
          const productParamList = createProductParamList(applicationProductsSelected);
          navigate(`${route}?productsSubmitted=${productParamList}`);
        };

        switch (applicationStatus) {
          case APPROVED:
          // falls through; APPROVED is only possible if mult acct is enabled
          case APPROVED_AND_BOOKED:
          // falls through
          case APPROVED_AND_ACCEPTED:
          // falls through
          case APPROVED_AND_BOOKING_ERROR:
            navigateWithProductParam("/decision/approved");
            break;
          case DECLINED:
            navigate("/decision/declined");
            break;
          case REVIEW:
            navigateWithProductParam("/decision/manual-review");
            break;
          case ERROR_WILL_RETRY_DECISIONING:
            navigate("/decision/additional-time-needed");
            break;
          case GOVID_SMS:
            navigateWithProductParam(govIdSelfieEnabled ? "/gov-id-selfie-sms" : "/gov-id-sms");
            break;
          case GOVID_EMAIL:
            navigateWithProductParam(govIdSelfieEnabled ? "/gov-id-selfie-email" : "/gov-id-email");
            break;
          case EXISTING_CUSTOMER_SIGN_IN: // uses Lassie status code
            navigate("/sign-in");
            break;
          case EXISTING_CUSTOMER_SSO_ENROLL: // uses Lassie status code
            navigate("/enroll");
            break;
          case EXISTING_CUSTOMER_BLOCK: // falls through - uses Lassie status code
          case SUBMITTED_WITH_ERRORS:
            navigate("/cannot-proceed");
            break;
          case ERROR_DECISIONING_SYSTEM: // falls through
          case ERROR_BACKEND_SYSTEM:
            // send this event so downstream can track decision errors - if this request fails, no error is thrown
            addNonCriticalEventToApplication({
              applicationReferenceId,
              event: "decisionErrorPageViewed",
            });
            navigate("/restart-application");
            break;
          case ERROR_DECISIONING_DATA: // falls through, 4xx decisioning errors are non-restartable
          default:
            // send this event so downstream can track decision errors - if this request fails, no error is thrown
            addNonCriticalEventToApplication({
              applicationReferenceId,
              event: "decisionErrorPageViewed",
            });
            // Default to non-restartable
            navigate("/something-went-wrong");
        }
      } catch (error) {
        sendErrorToNewRelic("Error occurred while submitting application", error);
        // send this event so downstream can track decision errors - if this request fails, no error is thrown
        addNonCriticalEventToApplication({ applicationReferenceId, event: "decisionErrorPageViewed" });
        navigate("/something-went-wrong");
      }
    },
    [
      govIdSelfieEnabled,
      submitTermsAndConditionsAndApp,
      navigate,
      applicationReferenceId,
      applicationProductsSelected,
    ]
  );

  const submitButtonId = useButtonId("submit");

  const readBackupWithholdingQuestion = getContentReader(backupWithholdingQuestionContent)("bodyText");
  const backupWithholdingYes = getContentReader(backupWithholdingYesContent)("bodyText");
  const backupWithholdingNo = getContentReader(backupWithholdingNoContent)("bodyText");
  const readTaxExemptionQuestion = getContentReader(taxExemptionQuestionContent)("bodyText");
  const readSingleMemberLLCTaxExemptionQuestion = getContentReader(
    singleMemberLLCTaxExemptionQuestionContent
  )("bodyText");

  const w9Attestation = getContentReader(w9AttestationContent)("bodyText");
  const readW9AttestationLine1 = getContentReader(w9AttestationLine1Content)("bodyText");
  const w9AttestationLine2Yes = getContentReader(w9AttestationLine2YesContent)("bodyText");
  const w9AttestationLine2No = getContentReader(w9AttestationLine2NoContent)("bodyText");
  const readW9AttestationLine3 = getContentReader(w9AttestationLine3Content)("bodyText");
  const readW9AttestationLine4 = getContentReader(w9AttestationLine4Content)("bodyText");
  const w9AttestationIrs = getContentReader(w9AttestationIrsContent)("bodyText");

  if (
    !readBackupWithholdingQuestion ||
    !backupWithholdingYes ||
    !backupWithholdingNo ||
    !readTaxExemptionQuestion ||
    !readSingleMemberLLCTaxExemptionQuestion ||
    !w9Attestation ||
    !readW9AttestationLine1 ||
    !w9AttestationLine2Yes ||
    !w9AttestationLine2No ||
    !readW9AttestationLine3 ||
    !readW9AttestationLine4 ||
    !w9AttestationIrs
  ) {
    return <LoadingSpinnerPage />;
  }

  const taxExemptionQuestion = splitChariotTextHtml(readTaxExemptionQuestion);

  return (
    <Page hidePromoBanner>
      {isSubmitting && <SubmitInProgressOverlay />}
      <form onSubmit={handleSubmit(submitHandler, selectFirstFieldWithError)}>
        <Grid ref={headingRef}>
          <Heading
            step="SECTION 4 OF 4"
            mainHeading="Review our legal information"
            subHeading="Read through the following documents and let us know you're okay with the account conditions."
          />
          <CenteredRow>
            <Col lg={8} md={8} sm={4}>
              <SectionHeader
                id="termsAndConditions_section_termsAndConditions_header"
                title="Terms & conditions"
              />
            </Col>
            <Col lg={8} md={8} sm={4}>
              <CertificationCheckbox
                name="hasAttestedToTermsAndConditions"
                label="I certify the following:"
                bold
                value={
                  watch("hasReadAndAgreedTS") &&
                  watch("hasAuthorizedObtainCreditBureauInfo") &&
                  watch("hasCertifiedAccountResponsibility")
                }
                onChange={event => {
                  const isChecked = event.target.checked;
                  setValue("hasReadAndAgreedTS", isChecked);
                  setValue("hasAuthorizedObtainCreditBureauInfo", isChecked);
                  setValue("hasCertifiedAccountResponsibility", isChecked);
                  clearErrors([
                    "hasReadAndAgreedTS",
                    "hasAuthorizedObtainCreditBureauInfo",
                    "hasCertifiedAccountResponsibility",
                  ]);
                }}
              />
            </Col>
            <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
              <CertificationCheckbox
                className="grv-margin__bottom--none"
                label="I have read and agreed to the following terms and conditions:"
                error={errors?.hasReadAndAgreedTS?.message}
                {...register("hasReadAndAgreedTS")}
              />
            </Col>
          </CenteredRow>
          <CenteredRow>
            <Col lg={8} md={8} offset={{ lg: 2, md: 1 }} sm={4}>
              <DisclosureLinks {...{ rgdaContent, eddnContent, factaContent }} />
            </Col>
          </CenteredRow>
          <CenteredRow>
            <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
              <CertificationCheckbox
                label="I authorize Capital One to obtain credit bureau information under my name."
                error={errors?.hasAuthorizedObtainCreditBureauInfo?.message}
                value={getValues("hasAuthorizedObtainCreditBureauInfo")}
                {...register("hasAuthorizedObtainCreditBureauInfo")}
              />
            </Col>
            <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
              <CertificationCheckbox
                label="I am the person who will be responsible for the account."
                error={errors?.hasCertifiedAccountResponsibility?.message}
                value={getValues("hasCertifiedAccountResponsibility")}
                {...register("hasCertifiedAccountResponsibility")}
              />
            </Col>
            <Col lg={8} md={8} sm={4}>
              <SectionHeader
                id="termsAndConditions_section_taxCertification_header"
                title="W-9 tax certification"
                compact
              />
            </Col>
          </CenteredRow>
          <BackupWithholding
            control={control}
            readContent={readBackupWithholdingQuestion}
            yesLabel={backupWithholdingYes}
            noLabel={backupWithholdingNo}
          />
          {storedBusinessLegalEntityType !== SOLE_PROPRIETORSHIP && (
            <Row>
              <Col lg={8} md={5} offset={{ lg: 2 }} sm={4}>
                <BooleanRadioListField
                  control={control}
                  name="isTaxExempt"
                  legendText={
                    isSingleMemberLLC ? (
                      readSingleMemberLLCTaxExemptionQuestion
                    ) : (
                      <>
                        {taxExemptionQuestion[0]}
                        {/* eslint-disable-next-line react/no-danger */}
                        <span dangerouslySetInnerHTML={{ __html: taxExemptionQuestion[1] }} />
                        {taxExemptionQuestion[2]}
                      </>
                    )
                  }
                  yesDisabled={isSingleMemberLLC}
                />
              </Col>
            </Row>
          )}

          {isTaxExemptWatch && (
            <Row>
              <Col lg={2} md={2} offset={{ lg: 2 }} sm={2}>
                <TaxExemptPayeeCode {...{ register, errors }} />
              </Col>
            </Row>
          )}
          <Row>
            <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
              <PenaltiesOfPerjury
                {...{
                  register,
                  errors,
                  w9Attestation,
                  readW9AttestationLine1,
                  readW9AttestationLine3,
                  readW9AttestationLine4,
                  w9AttestationIrs,
                }}
                w9AttestationLine2={
                  isSubjectToBackupWithholdingWatch ? w9AttestationLine2Yes : w9AttestationLine2No
                }
              />
            </Col>
          </Row>
          <NavigationButtons
            backRoute={backRoute}
            nextLoading={isSubmitting}
            nextText="Submit"
            nextProps={{ id: submitButtonId, gravityType: "action" }}
          />
        </Grid>
      </form>
    </Page>
  );
};

export default TermsAndConditions;
