import styled from "styled-components";
import mediaQuery from "../../../../utils/mediaQuery";
import { CenteredRow, Col, Grid, Wrapper } from "../../../shared/Layout";
import ApplicationStatusSection from "./ApplicationStatusSection";
import { convertProductTypeEnum, productKeyMapping } from "../../../../constants/productTypes";
import useFocusHeading from "../../../../utils/hooks/useFocusHeading";
import { FootnoteReference, footnoteReferenceContainerCSS } from "../../../shared/Footnotes";

const BookingInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;

  background-color: var(--grv-color-background-white);
  color: var(--grv-color-digital-gray-100);
  @media (${mediaQuery.medium}) {
    background-color: var(--grv-color-background-core-blue-50);
    color: var(--grv-color-white);
  }

  ${footnoteReferenceContainerCSS}
`;

const MainHeading = styled.h1.attrs({
  id: "page-main-heading",
})`
  margin: 0;
  font-weight: var(--grv-font-weight-semibold);

  color: var(--grv-color-digital-gray-100);
  font-size: var(--grv-size-font-medium-3);
  @media (${mediaQuery.medium}) {
    color: var(--grv-color-white);
    font-size: var(--grv-size-font-large-2);
  }
`;

const ProductDescriptionTable = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  margin-bottom: 0;
  @media (${mediaQuery.medium}) {
    margin-bottom: var(--grv-size-spacing-medium-1);
  }

  th {
    padding-top: var(--grv-size-spacing-medium-1);
    padding-bottom: var(--grv-size-spacing-medium-1);
    font-weight: var(--grv-font-weight-normal);

    border: var(--grv-size-border-width-1) solid var(--grv-color-digital-gray-15);
    padding-left: var(--grv-size-spacing-medium-1);
    padding-right: var(--grv-size-spacing-medium-1);
    font-size: var(--grv-size-font-small-1);
    color: var(--grv-color-digital-gray-100);
    @media (${mediaQuery.medium}) {
      border: var(--grv-size-border-width-2) solid var(--grv-color-white);
      padding-left: var(--grv-size-spacing-large-1);
      padding-right: var(--grv-size-spacing-large-1);
      font-size: var(--grv-size-font-medium-1);
      color: var(--grv-color-white);
    }
  }

  /* remove left border from table */
  tr th:first-child {
    border-left: 0;
  }

  /* remove right border from table */
  tr th:last-child {
    border-right: 0;
  }
`;

const OfferInformationText = styled(FootnoteReference).attrs({
  className: "grv-weight--semibold grv-margin__bottom--medium-2",
  to: "promoCodeInfo",
})`
  text-align: center;
  display: block;

  @media (${mediaQuery.medium}) {
    font-size: var(--grv-size-font-medium-3);
  }
`;

const SingleProductDescriptionTableContent = ({ readContent, productType }) => (
  // for single account, primary info is shown in left table cell
  // secondary info with footnote indicator is shown in right table cell
  <tbody>
    <tr>
      <th>{readContent(productKeyMapping[productType].primary)}</th>
      <th>
        <FootnoteReference to="productSecondaryInfo">
          {readContent(productKeyMapping[productType].secondary)}
        </FootnoteReference>
      </th>
    </tr>
  </tbody>
);

const MultiProductDescriptionTableContent = ({ readContent, leftProductType, rightProductType }) => (
  // for multiple accounts, first product name + primary info is shown in left table cell
  // second product name + primary info is shown in right table cell
  <tbody>
    <tr>
      <th>
        <strong>Business {convertProductTypeEnum(leftProductType)}</strong>
        <br />
        {readContent(productKeyMapping[leftProductType].primary)}
      </th>
      <th>
        <strong>Business {convertProductTypeEnum(rightProductType)}</strong>
        <br />
        {readContent(productKeyMapping[rightProductType].primary)}
      </th>
    </tr>
  </tbody>
);

const TitleElement = ({
  readContent,
  numberOfAccounts,
  treatment,
  collectiveAccountStatuses,
  promotionalOfferInformation,
  footnoteSectionId,
}) => {
  const headingRef = useFocusHeading();
  // map to a simple array of relevant products for easier indexing
  const productList = Object.values(collectiveAccountStatuses).reduce((acc, curr) => acc.concat(curr), []);

  return (
    <BookingInfoWrapper $footnoteSectionId={footnoteSectionId}>
      <Wrapper>
        <Grid ref={headingRef}>
          <CenteredRow>
            <Col lg={12}>
              <CenteredRow>
                <MainHeading>Congratulations!</MainHeading>
                <ApplicationStatusSection
                  numberOfAccounts={numberOfAccounts}
                  treatment={treatment}
                  collectiveAccountStatuses={collectiveAccountStatuses}
                  productList={productList}
                />
              </CenteredRow>
              {promotionalOfferInformation && (
                <CenteredRow>
                  <Col lg={12}>
                    <OfferInformationText>{promotionalOfferInformation}</OfferInformationText>
                  </Col>
                </CenteredRow>
              )}
              <CenteredRow>
                <ProductDescriptionTable>
                  {numberOfAccounts === 1 ? (
                    <SingleProductDescriptionTableContent
                      readContent={readContent}
                      productType={productList[0]}
                      footnoteSectionId={footnoteSectionId}
                    />
                  ) : (
                    <MultiProductDescriptionTableContent
                      readContent={readContent}
                      leftProductType={productList[0]}
                      rightProductType={productList[1]}
                    />
                  )}
                </ProductDescriptionTable>
              </CenteredRow>
            </Col>
          </CenteredRow>
        </Grid>
      </Wrapper>
    </BookingInfoWrapper>
  );
};

export default TitleElement;
