import shallow from "zustand/shallow";
import styled from "styled-components";
import { useCallback, useState } from "react";
import { ReactComponent as Megaphone } from "@c1/gravity-icons/dist/svg/ui-filled-megaphone-1-24.svg";
import { ReactComponent as PlusIcon } from "@c1/gravity-icons/dist/svg/ui-lined-plus-1-24.svg";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import Heading from "../../shared/Heading";
import { Grid, Col, CenteredRow } from "../../shared/Layout";
import Banner from "../../shared/Banner";
import NavigationButtons from "../../shared/NavigationButtons";
import Page from "../../shared/Page";
import { useNavigateUnlessReview } from "../../../utils/reviewContext";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useStore from "../../../store/store";
import BusinessCard from "./BusinessCard";
import {
  SOLE_PROPRIETORSHIP,
  LIMITED_PARTNERSHIP,
  GENERAL_PARTNERSHIP,
  LIMITED_LIABILITY_PARTNERSHIP,
  C_CORPORATION,
  S_CORPORATION,
  NON_PROFIT,
  LIMITED_LIABILITY_COMPANY,
} from "../../../constants/legalEntityTypes";
import { ReactComponent as CharityIcon } from "../../../assets/charity.svg";
import { ReactComponent as UserIcon } from "../../../assets/user.svg";
import { ReactComponent as MultiUserIcon } from "../../../assets/multi_user.svg";
import { ReactComponent as BusinessIcon } from "../../../assets/business.svg";
import { ReactComponent as OfficeIcon } from "../../../assets/office.svg";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import mediaQuery from "../../../utils/mediaQuery";
import { useBackRoute, useNextRouteProvider } from "../../../utils/hooks/useRouting";
import { INVALID_KYC_FIELDS_EMAIL_ADDRESS } from "../../../constants/prefillValues";
import PromoCodeIssueModal from "../../shared/PromoCodeIssueModal";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import { isFeatureEnabled } from "../../../utils/configSelector";
import { SBB_WEB } from "../../../constants/applicationChannelTypes";
import { processPromoValidation } from "../../../utils/promoCodeValidation";
import { PROMO_AND_PRODUCT_VALIDATION } from "../../../constants/promoValidationTypes";
import useProductIdMapping from "../../../utils/hooks/useProductIdMapping";

const BusinessCardsCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  gap: var(--grv-size-spacing-medium-1);

  & > * {
    flex: 0 0 100%;

    @media (${mediaQuery.medium}) {
      flex-basis: calc(50% - 8px);
    }

    @media (${mediaQuery.xlarge}) {
      flex-basis: calc(33.33% - 10.66px);
    }
  }
`;

const NavButtonsSpacer = styled.div`
  min-height: var(--grv-size-spacing-large-3);
`;

const getLegalEntityIcon = legalEntityType => {
  switch (legalEntityType) {
    case SOLE_PROPRIETORSHIP:
      return <UserIcon />;
    case LIMITED_PARTNERSHIP:
    case GENERAL_PARTNERSHIP:
    case LIMITED_LIABILITY_PARTNERSHIP:
      return <MultiUserIcon />;
    case C_CORPORATION:
    case S_CORPORATION:
      return <OfficeIcon />;
    case NON_PROFIT:
      return <CharityIcon />;
    case LIMITED_LIABILITY_COMPANY:
    default:
      return <BusinessIcon />;
  }
};

const ExistingBusinessSelection = () => {
  usePageTitle("Existing Business Selection");
  usePreventNavigation();
  const headingRef = useFocusHeading();

  const [
    storedApplicationProductsSelected,
    storedIsNewBusiness,
    storedIsNonProfit,
    storedExistingCustomerEligibleBusinesses,
    storedSelectedExistingBusiness,
    storedPromotionCode,
    offerFulfillmentEnabled,
    setDisplayPromotionCode,
    setPromotionMessageDetails,
    submitExistingBusinessSelection,
  ] = useStore(
    state => [
      state.applicationProductsSelected,
      state.isNewBusiness,
      state.isNonProfit,
      state.existingCustomerEligibleBusinesses,
      state.selectedExistingBusiness,
      state.promotionCode,
      isFeatureEnabled(state, "offerFulfillmentEnabled"),
      state.setDisplayPromotionCode,
      state.setPromotionMessageDetails,
      state.pageSubmit.submitExistingBusinessSelection,
    ],
    shallow
  );

  const applicationChannel = useApplicationChannel();
  const promoCodeEnabled = offerFulfillmentEnabled && applicationChannel === SBB_WEB;

  const [selectedExistingBusiness, setSelectedExistingBusiness] = useState(storedSelectedExistingBusiness);
  const [isNewBusiness, setIsNewBusiness] = useState(storedIsNewBusiness);
  const [isNonProfit, setIsNonProfit] = useState(storedIsNonProfit);
  const [isError, setIsError] = useState(false);
  const [promotionCodeUnavailable, setPromotionCodeUnavailable] = useState(false);
  const [promotionCodeExpired, setPromotionCodeExpired] = useState(false);

  const productIdMapping = useProductIdMapping();

  const navigate = useNavigateUnlessReview();
  const backRoute = useBackRoute();
  const nextRouteProvider = useNextRouteProvider();

  const handleClick = useCallback(
    existingBusiness => {
      setIsError(false);
      if (existingBusiness.businessSorId) {
        setIsNewBusiness(false);
        if (existingBusiness.businessSorId !== selectedExistingBusiness.businessSorId) {
          setSelectedExistingBusiness(existingBusiness);
          setIsNonProfit(
            (existingBusiness.legalEntityType === NON_PROFIT || existingBusiness.isNonProfit) ?? false
          );
        }
      } else {
        setSelectedExistingBusiness({});
        setIsNonProfit(false);
        setIsNewBusiness(true);
      }
    },
    [selectedExistingBusiness]
  );

  const onSubmit = useCallback(
    async (skipPromoCheck = false) => {
      if (Object.keys(selectedExistingBusiness).length === 0 && !isNewBusiness) {
        setIsError(true);
        return;
      }

      let submissionResult;
      if (!skipPromoCheck && promoCodeEnabled && storedPromotionCode) {
        try {
          const getProductPromoValidated = storedApplicationProductsSelected.find(
            product =>
              product.promotionCodeDetails?.promotionCode &&
              product.promotionCodeDetails?.passedValidations.includes(PROMO_AND_PRODUCT_VALIDATION)
          );

          if (!getProductPromoValidated) {
            setPromotionCodeUnavailable(true);
            setDisplayPromotionCode(false);
            return;
          }

          const {
            productType,
            promotionCodeDetails: { promotionCode },
          } = getProductPromoValidated;
          // notably, we rerun this even if the application somehow came back to this page
          // this ensures the promo is *always* valid for the business we are applying with
          const { isPromotionValid, errorCode, fundingPromotionMessage } = await processPromoValidation(
            productIdMapping[productType],
            productType,
            promotionCode,
            isNewBusiness ? undefined : selectedExistingBusiness?.businessSorId
          );
          setPromotionMessageDetails(productType, fundingPromotionMessage); // set this regardless - invalid promos will get disabled anyway
          if (!isPromotionValid) {
            setDisplayPromotionCode(false);
            switch (errorCode) {
              case "228007":
                setPromotionCodeExpired(true);
                return;
              default:
                setPromotionCodeUnavailable(true);
                return;
            }
          }
        } catch (error) {
          setPromotionCodeUnavailable(true);
          setDisplayPromotionCode(false);
          return;
        }

        setDisplayPromotionCode(true);
        submissionResult = await submitExistingBusinessSelection({
          selectedExistingBusiness,
          isNewBusiness,
          isNonProfit,
          isPromotionValid: true, // if we've reached this point, the promo code is valid
        });
      } else {
        submissionResult = await submitExistingBusinessSelection({
          selectedExistingBusiness,
          isNewBusiness,
          isNonProfit,
          // either the promo code was invalid or we skipped the check - either way, isPromotionValid will be falsy
        });
      }

      const { prefillFailure, prefillFailureReason } = submissionResult;

      if (prefillFailure) {
        // We route to invalid-email-address if email address is the only failure reason
        if (prefillFailureReason === INVALID_KYC_FIELDS_EMAIL_ADDRESS) {
          navigate("/invalid-email-address");
        } else {
          navigate("/cannot-proceed");
        }
      } else {
        navigate(nextRouteProvider({ isNewBusiness }));
      }
    },
    [
      isNewBusiness,
      isNonProfit,
      selectedExistingBusiness,
      promoCodeEnabled,
      storedPromotionCode,
      storedApplicationProductsSelected,
      productIdMapping,
      setDisplayPromotionCode,
      setPromotionMessageDetails,
      submitExistingBusinessSelection,
      navigate,
      nextRouteProvider,
    ]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(onSubmit);

  if (storedExistingCustomerEligibleBusinesses === null) {
    return <LoadingSpinnerPage />;
  }

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step="ABOUT YOUR COMPANY"
          mainHeading="Which business are you opening an account for?"
          subHeading="Select one of the businesses listed below."
        />
        <CenteredRow className="grv-margin__top--large-3">
          <Col lg={8} md={8} sm={4}>
            <Banner
              bannerText="These are the businesses currently associated with your account"
              bannerIcon={<Megaphone alt="" role="presentation" />}
            />
          </Col>
        </CenteredRow>
      </Grid>
      <Grid>
        {isError && (
          <CenteredRow className="grv-margin__top--large-3">
            <Col xl={8} lg={8} md={8} sm={4}>
              <span className="grv-text grv-color--interaction-red-50">
                You must make a selection to continue
              </span>
            </Col>
          </CenteredRow>
        )}
        <CenteredRow className={`${isError ? "grv-margin__top--small-2" : "grv-margin__top--large-1"}`}>
          <BusinessCardsCol className="grv-margin__top--medium-1" xl={8} lg={8} md={8} sm={4}>
            {storedExistingCustomerEligibleBusinesses.map(existingBusiness => (
              <BusinessCard
                key={existingBusiness.businessSorId}
                content={existingBusiness}
                handleClick={handleClick}
                isSelected={existingBusiness.businessSorId === selectedExistingBusiness?.businessSorId}
                icon={
                  existingBusiness.isNonProfit
                    ? getLegalEntityIcon(NON_PROFIT)
                    : getLegalEntityIcon(existingBusiness.legalEntityType)
                }
                isNonProfit={existingBusiness.isNonProfit}
              />
            ))}
            <BusinessCard
              content={{
                legalBusinessName: "I want to open an account for business not listed here",
              }}
              handleClick={handleClick}
              isSelected={isNewBusiness}
              icon={<PlusIcon />}
            />
          </BusinessCardsCol>
        </CenteredRow>
      </Grid>
      <NavButtonsSpacer />
      <NavigationButtons backRoute={backRoute} onNext={submitWithErrorHandling} />

      {promoCodeEnabled && (
        // Modal will be enabled if the code is unavailable or if there was an error looking up the code
        // The only difference between the two states is some messaging
        <PromoCodeIssueModal
          isOpen={promotionCodeUnavailable || promotionCodeExpired}
          onClose={() => {
            setPromotionCodeUnavailable(false);
            setPromotionCodeExpired(false);
          }}
          onContinue={() => submitWithErrorHandling(true)}
          idBase={promotionCodeUnavailable ? "promoCodeUnavailable" : "promoCodeExpired"}
          headingText={promotionCodeUnavailable ? "Promo unavailable" : "Promo expired"}
          showBackButton={!promotionCodeExpired}
        >
          {promotionCodeUnavailable ? (
            <span>
              The promo code <span className="grv-weight--semibold">{storedPromotionCode}</span> is
              unavailable for this business. Click “Back” to modify your business selection or click
              “Continue” to continue your application without a promo code.
            </span>
          ) : (
            <span>
              The promo code <span className="grv-weight--semibold">{storedPromotionCode}</span> has expired.
              Click “Continue” to continue your application without a promo code.
            </span>
          )}
        </PromoCodeIssueModal>
      )}
    </Page>
  );
};

export default ExistingBusinessSelection;
