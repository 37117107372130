import BusinessBranchUnfilled from "../../../assets/BusinessBranchUnfilled.svg";
import getContentReader from "../../../utils/getContentReader";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import TerminalPageTemplate from "../../shared/TerminalPageTemplate";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";
import useStore from "../../../store";

const CannotProceed = () => {
  usePageTitle("Cannot Proceed");
  const region = useStore(state => state.applicantAddressRegion);
  // Region has 3 values, InFoot, OutOfFoot, and Expansion. Expansion messaging is considered the same as OutOfFoot.
  const customerInFoot = region === "InFoot";
  const content = usePreSubmitTreatment(
    "preSubmitFriction",
    customerInFoot ? "frictionForInFoot" : "frictionForOutOfFoot"
  );
  if (!content) {
    return <LoadingSpinnerPage />;
  }

  return (
    <TerminalPageTemplate
      image={BusinessBranchUnfilled}
      mediumIcon
      readContent={getContentReader(content)}
      enableButton={customerInFoot}
      extraSpacingAfterMainHeading={false}
      buttonClassName=""
    />
  );
};

export default CannotProceed;
