const defaultFatalErrorPageContent = {
  headerTextProd: "Something went wrong",
  headerTextNonProd: "Something went wrong",
  bodyTextProd:
    "Looks like there was an issue opening your account online. Restart your application. You can work with a branch ambassador to complete account opening through an alternative process.",
  bodyTextNonProd:
    "Looks like there was an issue opening your account online. Restart your application. You can work with a branch ambassador to complete account opening through an alternative process.",
  buttonTextProd: "Restart Application",
  buttonTextNonProd: "Restart Application",
  buttonLinkTargetNonProd: "https://businessapply-qa.clouddqt.capitalone.com/",
  buttonLinkTargetProd: "https://businessapply.capitalone.com/",
  branchLinkTextProd: "Find A Branch",
  branchLinkTextNonProd: "Find A Branch",
  branchLinkTargetProd: "https://locations.capitalone.com/",
  branchLinkTargetNonProd: "https://locations.capitalone.com/",
  contentId: "apiFailedFatal",
};

export default defaultFatalErrorPageContent;
