import styled from "styled-components";
import { ReactComponent as RightArrow } from "@c1/gravity-icons/dist/svg/ui-lined-arrow-right-1-24.svg";
import ExternalLink from "../../shared/ExternalLink";
import mediaQuery from "../../../utils/mediaQuery";
import { useCheckboxId } from "../../../utils/hooks/usePageScopedId";
import { BUSINESS_ADVANTAGE_SAVINGS } from "../../../constants/productTypes";
import combineStrings from "../../../utils/combineStrings";

const Card = styled.div.attrs({
  className:
    "grv-elevation--level-1 grv-container--radius-4 grv-padding__top--medium-2 grv-padding__bottom--medium-2 grv-padding__left--medium-1 grv-padding__right--medium-1",
})`
  border-top-width: var(--grv-size-spacing-small-2);
  border-top-color: var(
    --grv-color-${({ $darkBorder }) => ($darkBorder ? "background-core-blue-60" : "interaction-blue-70")}
  );

  /* Selected cards need additional indicators */
  &:has(input:checked) {
    outline: 3px solid var(--grv-color-interaction-blue-50);
    box-shadow: 0px 4px 16px var(--grv-color-shadow-default);
  }

  /* Checkbox color needs to be slightly brightened from Gravity default */
  &&& input:checked {
    background-color: var(--grv-color-interaction-blue-50);
    border-color: var(--grv-color-interaction-blue-50);
  }

  /* Make font smaller and semi bold on iPad only - this is a request from design to get the card content to fit on certain screen widths */
  @media (${mediaQuery.mediumLarge}) and (max-width: 850px) {
    label {
      font-size: var(--grv-size-font-medium-1);
      font-weight: var(--grv-font-weight-semibold);
    }
  }
`;

const ProductTaglinePill = styled.div.attrs({
  className: "grv-margin__top--medium-1 grv-margin__bottom--medium-1",
})`
  border-radius: var(--grv-size-border-radius-circle);
  text-align: center;
  line-height: 26px;
`;

const DetailsLink = styled(ExternalLink)`
  text-decoration: none;
`;

const RightArrowIcon = styled(RightArrow)`
  fill: var(--grv-color-icon-interaction-blue-50);
  height: 12px;
  aspect-ratio: 1;
  margin-left: 10px;

  & path {
    stroke-width: 2.5px;
    stroke: var(--grv-color-icon-interaction-blue-50);
  }
`;

const ProductCard = ({
  productType,
  handleCardClick,
  isSelected,
  contentReader,
  productName,
  productTagline,
  primaryContentHeader,
  primaryContent,
  secondaryContentHeader,
  secondaryContent,
}) => (
  // Savings product card has a darker border
  <Card $darkBorder={productType === BUSINESS_ADVANTAGE_SAVINGS}>
    <label className="grv-text--medium-2 grv-weight--light grv-label__header">
      {productName}&#174;
      <input
        type="checkbox"
        checked={isSelected}
        id={useCheckboxId(`productType_${productType}`)}
        className="grv-checkbox"
        onChange={handleCardClick}
        onKeyPress={event => event.key === "Enter" && handleCardClick()}
      />
    </label>
    <ProductTaglinePill
      className={combineStrings(
        "grv-color--interaction-blue-70 grv-text--small-1 grv-weight--semibold",
        productType === BUSINESS_ADVANTAGE_SAVINGS
          ? "grv-background--interaction-blue-10"
          : "grv-background--accent-light-blue-10"
      )}
    >
      {productTagline}
    </ProductTaglinePill>
    <div className="grv-text--small-2 grv-weight--semibold grv-margin__bottom--small-2">
      {primaryContentHeader}
    </div>
    <div className="grv-margin__bottom--medium-2">{primaryContent}</div>
    <div className="grv-text--small-2 grv-weight--semibold grv-margin__bottom--small-2">
      {secondaryContentHeader}
    </div>
    <div className="grv-margin__bottom--medium-1">{secondaryContent}</div>
    <DetailsLink
      className="grv-text--small-2 grv-weight--semibold"
      href={contentReader("disclosure").disclosureUrl}
    >
      Additional Details
      <RightArrowIcon />
    </DetailsLink>
  </Card>
);

export default ProductCard;
