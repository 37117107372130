import ExplanationModal from "./ExplanationModal";

const BackupWithholdingModal = ({ active, onOk }) => (
  <ExplanationModal
    {...{ active, onOk }}
    idBase="backupWithholding"
    headingText="Backup Withholding"
    className="grv-dialog--large"
    buttonClassName="grv-button--progressive"
    shouldCloseOnEsc
    shouldCloseOnOverlayClick
  >
    <h2 className="grv-row grv-row__margin--xs--small grv-row__margin--sm--small grv-row__margin--md--small grv-row__margin--ml--small grv-row__margin--lg--small grv-row__margin--xl--small grv-row__margin--ds--small grv-row--odd grv-margin__top--medium-1 grv-margin__bottom--large-1">
      What is backup withholding?
    </h2>
    <p className="grv-text__heading--normal grv-weight--normal grv-margin__bottom--medium-1">
      Sometimes we have to withhold and send 24% (rate subject to change) of the interest we pay to you, to
      the IRS instead. This is called backup withholding.
    </p>
    <p className="grv-text__heading--normal grv-weight--normal grv-margin__bottom--medium-1">
      You may be subject to - or become subject to - backup withholding: If you do not provide us with a
      correct taxpayer identification number (TIN), your Social Security Number (SSN) or Employment
      Identification Number (SSN), and the IRS later notifies us that the TIN is incorrect.
    </p>
    <p className="grv-text__heading--normal grv-weight--normal grv-margin__bottom--medium-1">
      If you fail to report or you under report interest and dividends on your tax return and the IRS later
      notifies us to start withholding on interest or dividends. The IRS will first send you several notices.
    </p>
    <p className="grv-text__heading--normal grv-weight--normal grv-margin__bottom--medium-1">
      You certify that you&apos;re subject to backup withholding for under reporting of interest and dividends
      on the W-9 substitute form that we will present to you later by selecting “Yes”.
    </p>
    <h2 className="grv-text__heading--medium grv-margin__top--small-2 grv-margin__bottom--large-1">
      Does it apply to me?
    </h2>
    <p className="grv-text__heading--normal grv-weight--normal grv-margin__bottom--medium-1">
      This applies if the IRS has sent you up to 4 letters/notices informing you that you are subject to
      backup withholding on interest and dividends, because you&apos;ve under reported interest or dividends
      on your tax return. If this applies, select “Yes” below. If not, select “No”.
    </p>
    <p className="grv-text__heading--normal grv-weight--normal grv-margin__bottom--medium-1">
      Please note that if you were previously notified by the IRS that you are subject to backup withholding,
      and the IRS later wrote to you that you weren&apos;t, you are no longer subject to backup withholding
      and can select “No”.
    </p>
  </ExplanationModal>
);

export default BackupWithholdingModal;
