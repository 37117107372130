import shallow from "zustand/shallow";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import Page from "../../shared/Page";
import { Grid } from "../../shared/Layout";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import Heading from "../../shared/Heading";
import useStore from "../../../store/store";
import NavigationButtons from "../../shared/NavigationButtons";
import useRouting from "../../../utils/hooks/useRouting";
import ApplicationReviewBody from "./ApplicationReviewBody";
import PrefilledBusinessApplicationReviewBody from "./PrefilledBusinessApplicationReviewBody";
import { isFeatureEnabled } from "../../../utils/configSelector";

const ApplicationReview = () => {
  usePageTitle("Review");
  usePreventNavigation();
  const headingRef = useFocusHeading();
  const [
    isNewBusiness,
    promotionCode,
    displayPromotionCode,
    offerFulfillmentEnabled,
    limitedOfferEligibilityEnabled,
  ] = useStore(
    state => [
      state.isNewBusiness,
      state.promotionCode,
      state.displayPromotionCode,
      isFeatureEnabled(state, "offerFulfillmentEnabled"),
      isFeatureEnabled(state, "limitedOfferEligibilityEnabled"),
    ],
    shallow
  );
  // do not show promo pill if limited offer eligibility is enabled
  const showPromoPill =
    promotionCode && displayPromotionCode && offerFulfillmentEnabled && !limitedOfferEligibilityEnabled;
  const [backRoute, nextRoute] = useRouting();

  return (
    <Page hidePromoBanner>
      <Grid ref={headingRef}>
        <Heading
          step="APPLICATION REVIEW"
          mainHeading="Make sure everything looks right"
          subHeading="Before you submit, double check your application for accuracy."
        />
        {isNewBusiness ? (
          <ApplicationReviewBody showPromoPill={showPromoPill} />
        ) : (
          <PrefilledBusinessApplicationReviewBody showPromoPill={showPromoPill} />
        )}
      </Grid>
      <NavigationButtons
        preventSubmit // no need for form logic on this page - let the subforms handle that
        nextRoute={nextRoute}
        backRoute={backRoute}
      />
    </Page>
  );
};

export default ApplicationReview;
