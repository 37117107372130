import styled from "styled-components";

/* eslint-disable no-nested-ternary */
const Icon = styled.img.attrs({
  alt: "",
})`
  height: ${({ $height, $large, $medium, $small }) =>
    $large ? "128px" : $medium ? "96px" : $small ? "87px" : $height};
  aspect-ratio: 1;
`;
/* eslint-enable */

export default Icon;
