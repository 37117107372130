import { useNavigate } from "react-router-dom";
import DecisionTemplate from "./DecisionTemplate";
import userImage from "../../../assets/user.svg";
import getContentReader from "../../../utils/getContentReader";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import { useDecisionPlatformTreatment } from "../../../utils/hooks/usePostSubmitTreatment";

const DecisionManualReview = () => {
  usePageTitle("Account Subject to Manual Review");
  const content = useDecisionPlatformTreatment("inProcess", "maoManualReview");
  const navigate = useNavigate();

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(content);

  return (
    <DecisionTemplate
      iconSrc={userImage}
      headerText={readContent("headerText")}
      bodyText={readContent("bodyText")}
      buttonLinkId="review-path-call-to-action"
      onButtonLinkClicked={() => navigate("/manual-review-faq")}
      buttonType="action"
      buttonText={readContent("buttonText")}
      showCEMPSurvey
    />
  );
};

export default DecisionManualReview;
