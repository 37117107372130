import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Controller, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import { CenteredRow, Col, Grid } from "../Layout";
import { US_STATES } from "../../../constants/usStates";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import {
  getAddressAutocompleteSuggestions,
  getPlaceDetails,
  validateStreetAddress,
} from "../../../api/sbaoAppEndpoints";
import { Item, QueryCombobox } from "../Combobox";
import TextInput from "../TextInput";
import Select from "../Select";
import combineRefs from "../../../utils/combineRefs";
import combineStrings from "../../../utils/combineStrings";
import { BlueInfoTooltip } from "../Tooltip";
import useStore from "../../../store/store";
import { isFeatureEnabled } from "../../../utils/configSelector";
import { useFieldId, useInputId, useDropdownId } from "../../../utils/hooks/usePageScopedId";

// Synchronous address validations
export const addressSchema = yup.object().shape({
  addressLine1: yup
    .string()
    .required("Enter your street address")
    .max(38)
    .test(
      "validateAddressLine1",
      "Enter your street address",
      value => !/^[0-9]+$/.test(value.replace(/[^a-zA-Z0-9]/g, ""))
    ),
  addressLine2: yup.string().notRequired().max(38),
  city: yup
    .string()
    .required("Enter your city")
    .max(20)
    .matches(/^[^0-9]*$/, "City name cannot contain numbers"),
  state: yup
    .string()
    .required("Enter your state")
    .test("validateState", "Enter your state", state => US_STATES.find(({ value }) => value === state))
    .notOneOf([PLACEHOLDER_NONE], "Enter your state"),
  zip: yup
    .string()
    .required("Enter your ZIP code")
    .min(5, "Enter a valid 5-digit ZIP code")
    .max(5, "Enter a valid 5-digit ZIP code")
    .test(
      "validateZipcode",
      "Enter a valid 5-digit ZIP code",
      value => !["00000", "11111", "33333", "66666", "77777", "88888", "99999"].includes(value)
    ),
});

const makeInvalidAddressErrorMessage = kind =>
  `We cannot accept ${kind} on a small business bank account application. Try again using your physical address.`;

// Utility for validating an address and setting errors appropriately
// This hook returns two values in an array: [validationState, validate].
//
// The first value, validationState, should simply be spread directly to the AddressFields component.
// That is, <AddressFields {...validationState} />. This value helps control the "warn" state that
// this validation can use to avoid rejecting missing addresses.
//
// The second value, validate, is a function which should be called as part of the submit
// handling logic of address fields. It will return two flags in an object:
// { isAddressValid: boolean, isAddressAcceptable: boolean }
//
// If validateStreetAddress finds the address, and it is not a CMRA, PO Box, DPO, or Embassy, the
// address is considered acceptable, and the isAddressValid flag returned by validate will be true.
// If validateStreetAddress finds that the address IS a CMRA, PO Box, DPO, or Embassy, AND the
// relevant flag (see arguments) to allow this is true, the address is considered acceptable, and
// the isAddressValid flag returned by validate will be true.
// If validateStreetAddress cannot find the address, and errorOnMissing is set to true,
// the address is considered *unacceptable* and both flags returned by validate will be false.
//
// However, if validateStreetAddress cannot find the address, and errorOnMissing is left as its default of
// false, validationState will change to tell AddressFields to render a special banner. If the address changes,
// AddressFields will clear this banner and state. But, if validate is called again with the same address while
// validation is in this state, the address is considered *acceptable*, and validate returns that isAddressValid
// is still *false* but isAddressAcceptable will become *true*.
// This means that when errorOnMissing is left as false, missing/invalid addresses will be accepted by the
// validate call on the *second* attempt (or any subsequent ones, as in the case of multiple addresses in the
// same form).
//
// Whenever the address is unacceptable, it will call setError on the appropriate fields in the Address form.
//
// The hook takes one configuration object as its argument, containing:
//    - setError and getValues, which are *required*, and should come right from useForm.
//    - baseField, which defaults to "address", and should be the same baseField as was given to <AddressFields />
//    - allowCMRA, allowPOBox, allowDPO, and allowEmbassy are all booleans, and can be set to true
//      to override the validation behavior (e.g., for mailing addresses). These are optional, with all
//      flags defaulting to false.
//    - errorOnMissing, described in detail above. When set to true will cause addresses that cannot be found
//      to be treated as errors as errors/unacceptable. This defaults to false so they can be treated as warnings.
export const useAddressValidation = ({
  setError,
  getValues,
  baseField = "address",
  allowCMRA = false,
  allowPOBox = false,
  allowDPO = false,
  allowEmbassy = false,
  errorOnMissing = false,
  updateRegion = false,
}) => {
  // new address service flag
  const newAddressServiceEnabled = useStore(state => isFeatureEnabled(state, "newAddressServiceEnabled"));

  // applicantAddressRegion is used to determine if the applicant is in footprint or out of footprint to display acceptable error messaging
  const setApplicantAddressRegion = useStore(state => state.setApplicantAddressRegion);

  // state of the warning banner
  const [displayWarning, setDisplayWarning] = useState(false);
  // history of addresses checked by this validator
  const invalidAddresses = useRef([]);

  const validate = useCallback(async () => {
    const addressValues = getValues([
      `${baseField}.addressLine1`,
      `${baseField}.addressLine2`,
      `${baseField}.city`,
      `${baseField}.state`,
      `${baseField}.zip`,
    ]);
    const [addressLine1, addressLine2, city, stateCode, postalCode] = addressValues;
    const addressValuesUpperCase = addressValues.map(field => field.toUpperCase());

    // if the current address is in invalidAddresses...
    if (invalidAddresses.current.find(old => old.every((field, i) => field === addressValuesUpperCase[i]))) {
      // ...we know this address was missing/not a valid street address already.
      // To avoid popping the warning banner again (and confusing the user), we can
      // skip all validation and just return true.
      setDisplayWarning(false); // ensure the banner goes away
      return { isAddressValid: false, isAddressAcceptable: true };
    }

    const {
      isValidStreetAddress,
      isCmraAddress,
      isPostOfficeBox,
      isDpoAddress,
      isEmbassyAddress,
      isMismatchedZipcode,
      region,
    } = await validateStreetAddress(
      {
        addressLine1,
        addressLine2,
        city,
        stateCode,
        postalCode,
      },
      newAddressServiceEnabled
    );

    // applicantAddressRegion determines how close an applicant is to a capital one location
    if (updateRegion) {
      setApplicantAddressRegion(region);
    }

    if (!isValidStreetAddress) {
      if (errorOnMissing) {
        setError(`${baseField}.addressLine1`, {
          type: "validation",
          message: "We could not locate that Address, please try again.",
        });
        return { isAddressValid: false, isAddressAcceptable: false };
      }
      // track this missing address so we only validate it once
      invalidAddresses.current.push(addressValuesUpperCase);
      // make sure the banner is displayed
      setDisplayWarning(true);

      // Note that the above state change is *not* reflected here!
      // It would not be reflected until the *next* validator call, for
      // now displayWarning will have whatever value it had at the start
      // of this validation call.
      // If it was true, we want to accept the missing address. If it was
      // false, we want to reject the missing address (because we want the
      // user to confirm it first).
      // Therefore we can just return the state of displayWarning as isAddressAcceptable!
      return { isAddressValid: false, isAddressAcceptable: displayWarning };
    }

    if (isMismatchedZipcode) {
      setError(`${baseField}.zip`, {
        type: "validation",
        message: "Your entered ZIP Code does not match your entered Address.",
      });
      setDisplayWarning(false);
      return { isAddressValid: false, isAddressAcceptable: false };
    }

    if (isCmraAddress && !allowCMRA) {
      setError(`${baseField}.addressLine1`, {
        type: "validation",
        message: makeInvalidAddressErrorMessage("a Commercial Mail Receiving Agency"),
      });
      setDisplayWarning(false);
      return { isAddressValid: false, isAddressAcceptable: false };
    }

    if (isPostOfficeBox && !allowPOBox) {
      setError(`${baseField}.addressLine1`, {
        type: "validation",
        message: makeInvalidAddressErrorMessage("a PO Box"),
      });
      setDisplayWarning(false);
      return { isAddressValid: false, isAddressAcceptable: false };
    }

    if (isDpoAddress && !allowDPO) {
      setError(`${baseField}.addressLine1`, {
        type: "validation",
        message: makeInvalidAddressErrorMessage("Diplomatic Post Offices"),
      });
      setDisplayWarning(false);
      return { isAddressValid: false, isAddressAcceptable: false };
    }

    if (isEmbassyAddress && !allowEmbassy) {
      setError(`${baseField}.addressLine1`, {
        type: "validation",
        message: makeInvalidAddressErrorMessage("addresses with embassy zipcodes"),
      });
      setDisplayWarning(false);
      return { isAddressValid: false, isAddressAcceptable: false };
    }

    return { isAddressValid: true, isAddressAcceptable: true };
  }, [
    errorOnMissing,
    allowCMRA,
    allowPOBox,
    allowDPO,
    allowEmbassy,
    displayWarning,
    setError,
    getValues,
    baseField,
    newAddressServiceEnabled,
    setApplicantAddressRegion,
    updateRegion,
  ]);

  return [
    {
      showInvalidAddressWarning: displayWarning,
      clearInvalidAddressWarning: useCallback(() => setDisplayWarning(false), []),
    },
    validate,
  ];
};

const getAddressSuggestions = newAddressServiceEnabled => async (query, oldSuggestions) => {
  try {
    const { suggestions } = await getAddressAutocompleteSuggestions(
      encodeURIComponent(query),
      "GOOGLE",
      newAddressServiceEnabled
    );
    return suggestions;
  } catch (err) {
    return oldSuggestions;
  }
};

const renderAddressItem = ({ fullAddress, addressLine1, placeId }, query) => {
  const addressParts = fullAddress.split(/\s+/);
  const queryParts = query.toLowerCase().split(/\s+/);

  return (
    <Item key={placeId} id={`address-${placeId}`} selectionValue={{ addressLine1, placeId }}>
      <span aria-label={fullAddress}>
        {addressParts.map((part, i) => {
          /* eslint-disable react/no-array-index-key */
          if (i >= queryParts.length) {
            return <span key={i}>{part} </span>;
          }
          const queryPart = queryParts[i];
          if (!part.toLowerCase().startsWith(queryPart)) {
            return <span key={i}>{part} </span>;
          }

          const boldPart = part.slice(0, queryPart.length);
          const restPart = part.slice(queryPart.length);
          return (
            <span key={i}>
              <span className="grv-weight--semibold">{boldPart}</span>
              {restPart}{" "}
            </span>
          );
          /* eslint-enable react/no-array-index-key */
        })}
      </span>
    </Item>
  );
};

const AddressLine1InputRow = forwardRef(
  ({ baseField, idBase, label, helper, onChange, ...rest }, passedRef) => {
    const { control, setValue, trigger } = useFormContext();
    const newAddressServiceEnabled = useStore(state => isFeatureEnabled(state, "newAddressServiceEnabled"));
    const comboboxIdBase = useFieldId(`${idBase}Line1`, "Combobox");

    return (
      <CenteredRow>
        <Col lg={8} md={8} sm={4}>
          <Controller
            control={control}
            name={`${baseField}.addressLine1`}
            render={({ field, formState }) => (
              <QueryCombobox
                getResults={getAddressSuggestions(newAddressServiceEnabled)}
                renderItem={renderAddressItem}
                idBase={comboboxIdBase}
                listboxLabel="Addresses"
                onSelected={async ({ placeId }) => {
                  const {
                    addressLine1,
                    addressLine2 = "",
                    stateCode,
                    city,
                    postalCode,
                  } = await getPlaceDetails(placeId, newAddressServiceEnabled);
                  // MISSING TEST COVERAGE: Unable to cover addressLine2 it sets a default empty string
                  // istanbul ignore next
                  setValue(baseField, {
                    addressLine1: addressLine1 ?? "",
                    addressLine2: addressLine2 ?? "",
                    state: stateCode ?? "",
                    city: city ?? "",
                    zip: postalCode ?? "",
                  });
                  trigger(baseField);
                  return addressLine1;
                }}
                inputProps={{
                  label,
                  helper,
                  ...field,
                  ...rest,
                  ref: combineRefs(passedRef, field.ref),
                  onChange: event => {
                    onChange?.(event);
                    field.onChange(event);
                  },
                  error: formState?.errors?.[baseField]?.addressLine1?.message,
                }}
              />
            )}
          />
        </Col>
      </CenteredRow>
    );
  }
);

const AddressLine2InputRow = ({ id, baseField, onChange }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <CenteredRow>
      <Col lg={8} md={8} sm={4}>
        <TextInput
          id={id}
          label="Unit/Suite/Floor (if Applicable)"
          error={errors?.[baseField]?.addressLine2?.message}
          {...register(`${baseField}.addressLine2`, { onChange })}
        />
      </Col>
    </CenteredRow>
  );
};

const CityInputCol = ({ id, baseField, onChange }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Col lg={3} md={3} sm={4}>
      <TextInput
        id={id}
        label="City"
        error={errors?.[baseField]?.city?.message}
        {...register(`${baseField}.city`, { onChange })}
      />
    </Col>
  );
};

const StateInputCol = ({ id, baseField, onChange }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Col lg={3} md={3} sm={4}>
      <Select
        id={id}
        label="State"
        error={errors?.[baseField]?.state?.message}
        {...register(`${baseField}.state`, { onChange })}
      >
        <option className="grv-select__placeholder" value={PLACEHOLDER_NONE} disabled>
          Select a state
        </option>
        {US_STATES.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </Col>
  );
};

const ZipInputCol = ({ id, baseField, onChange }) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Col lg={2} md={2} sm={4}>
      <Controller
        render={({ field: { ref, ...rest } }) => (
          <NumberFormat
            customInput={TextInput}
            id={id}
            getInputRef={ref}
            label="ZIP Code"
            mask=""
            format="#####"
            error={errors?.[baseField]?.zip?.message}
            {...register(`${baseField}.zip`, { onChange })}
            {...rest}
          />
        )}
        name={`${baseField}.zip`}
        control={control}
      />
    </Col>
  );
};

const AddressInvalidWarning = id => (
  <CenteredRow>
    <Col lg={8} md={8} sm={4}>
      <div id={id} className="grv-alert--warning grv-margin__bottom--medium-2">
        We can&apos;t validate your address. Check that your address is correct and try again.
      </div>
    </Col>
  </CenteredRow>
);

// Simple wrapper around a Tooltip that handles the icon and styling for the address label tooltips,
// and supplies the standard explanation text as the tooltip content. This is broken out as a separate
// component to allow addresses to include or exclude as needed. Should have an id passed as a prop,
// and also provided to the addressLine1DescribedBy of the containing AddressFields
export const AddressInformationTooltip = props => (
  <BlueInfoTooltip anchor="bottom-left" {...props}>
    Addresses cannot be for a Registered Agent, Commercial Mail Receiving Agencies (CMRA, e.g., The UPS
    Store), PO Boxes, Post Office Box Street Address (PBSAs), Diplomatic PO Boxes, and embassies
  </BlueInfoTooltip>
);

// Address field grid
// Props are
//  - baseField is the name of the containing object field in the hook form. This component will
//    register addressLine1, addressLine2, city, state, and zip within this field, e.g., it will
//    call register(`${baseField}.addressLine1`). Defaults to "address"
//  - idBase is the base of all field ids this component will add, and defaults to the value of
//    baseField.
//  - addressLine1Label and addressLine1Helper are used for the label and helper text of the
//    line 1 field's QueryCombobox
//  - addressLine1DescribedBy can provide additional id(s) to the aria-describedby of the underlying
//    input element of the line 1 field
//  - showInvalidAddressWarning + clearInvalidAddressWarning, which should not be provided manually
//    and instead are sourced from the first value output by useAddressValidation
export const AddressFields = ({
  baseField = "address",
  idBase = baseField,
  addressLine1Label = "",
  addressLine1Helper = "",
  addressLine1DescribedBy = null,
  showInvalidAddressWarning,
  clearInvalidAddressWarning,
}) => {
  const {
    clearErrors,
    formState: { isSubmitted },
  } = useFormContext();

  // used to clear the error state of addressLine1 when any address field changes,
  // but only if the form has already been submitted
  // this is mostly to clear async errors, since sync errors will be immediately restored
  const clearLine1Error = useCallback(() => {
    if (isSubmitted) {
      clearInvalidAddressWarning?.();
      clearErrors(`${baseField}.addressLine1`);
    }
  }, [isSubmitted, clearInvalidAddressWarning, clearErrors, baseField]);

  const clearZipAndLine1Errors = useCallback(() => {
    clearLine1Error();
    clearErrors(`${baseField}.zip`);
  }, [clearLine1Error, clearErrors, baseField]);

  const addressLine1Ref = useRef();
  useEffect(() => {
    if (showInvalidAddressWarning) {
      // When the invalid warning banner appears, make a *best effort* to scroll to
      // and focus the address line 1 input. If there are multiple errors on the page,
      // this can conflict with similar logic that scrolls to the first of those errors.
      // However, in practice those errors would *generally* be created *before* the
      // warning banner would even appear. The majority of users will simply be scrolled
      // back to the address line 1 input and see the banner.
      addressLine1Ref.current?.scrollIntoView?.({ block: "center" });
      addressLine1Ref.current?.focus({ preventScroll: true });
    }
  }, [showInvalidAddressWarning]);
  const warningBannerId = `${idBase}-invalid-warning`;

  return (
    <Grid>
      {showInvalidAddressWarning && <AddressInvalidWarning id={warningBannerId} />}
      <AddressLine1InputRow
        idBase={idBase}
        baseField={baseField}
        label={addressLine1Label}
        helper={addressLine1Helper}
        onChange={clearInvalidAddressWarning}
        ref={addressLine1Ref}
        aria-describedby={combineStrings({
          warningBannerId: showInvalidAddressWarning,
          [addressLine1DescribedBy]: addressLine1DescribedBy,
        })}
      />
      <AddressLine2InputRow
        id={useInputId(`${idBase}Line2`)}
        baseField={baseField}
        onChange={clearLine1Error}
      />
      <CenteredRow>
        <CityInputCol
          id={useInputId(`${idBase}City`)}
          baseField={baseField}
          onChange={clearZipAndLine1Errors}
        />
        <StateInputCol
          id={useDropdownId(`${idBase}State`)}
          baseField={baseField}
          onChange={clearZipAndLine1Errors}
        />
        <ZipInputCol
          id={useInputId(`${idBase}Zipcode`)}
          baseField={baseField}
          onChange={clearZipAndLine1Errors}
        />
      </CenteredRow>
    </Grid>
  );
};
