import shallow from "zustand/shallow";
import styled from "styled-components";
import ReactModal from "react-modal";
import { useState, useCallback, useMemo } from "react";
import { accountPurposeDisplay } from "../../../constants/accountPurposeTypes";
import { businessControllerTypeDisplay } from "../../../constants/businessControllerTypes";
import { partnershipTypesDisplay } from "../../../constants/partnershipTypes";
import {
  businessTaxClassificationDisplay,
  C_CORPORATION,
  S_CORPORATION,
} from "../../../constants/businessTaxClassifications";
import { organizationTypeDisplayNames, OTHER } from "../../../constants/charitableOrganizationTypes";
import { citizenshipTypeDisplay, US_CITIZEN } from "../../../constants/citizenshipTypes";
import { getCountryName } from "../../../constants/countryTypes";
import {
  convertLegalEntityEnumToDisplay,
  LIMITED_LIABILITY_COMPANY,
  SOLE_PROPRIETORSHIP,
} from "../../../constants/legalEntityTypes";
import { ownershipTypesDisplay } from "../../../constants/ownershipTypes";
import { convertProductTypeEnum } from "../../../constants/productTypes";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import useStore from "../../../store/store";
import LineItem, { StaticLineItem } from "../../shared/LineItem";
import { formatSSN, formatEIN, combineParts, formatPhoneNumber } from "../../../utils/reviewFormatters";
import { SSN } from "../../../constants/businessTaxIdTypes";
import { annualBusinessRevenueRangeDisplay } from "../../../constants/annualBusinessRevenueRanges";
import {
  ALL_SPECIAL_LOGIC_HRBR_ENUMS,
  ALL_STANDARD_LOGIC_HRBR_ENUMS,
  SPECIAL_DISPLAY_QUESTIONS,
  jewelryRevenueRangeDisplay,
} from "../../../constants/riskyBusinessQuestionsValues";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import { SBB_WEB } from "../../../constants/applicationChannelTypes";
import { isFeatureEnabled } from "../../../utils/configSelector";

const legalEntityDisplayNames = {
  [SOLE_PROPRIETORSHIP]: convertLegalEntityEnumToDisplay(SOLE_PROPRIETORSHIP),
  [LIMITED_LIABILITY_COMPANY]: "Multi Member LLC",
  ...partnershipTypesDisplay,
  [C_CORPORATION]: businessTaxClassificationDisplay[C_CORPORATION],
  [S_CORPORATION]: businessTaxClassificationDisplay[S_CORPORATION],
};

export const LegalEntityType = () => (
  <LineItem
    id="item_companyType"
    title="Company Type"
    selector={({ businessLegalEntityType, isSingleMemberLLC }) =>
      businessLegalEntityType === LIMITED_LIABILITY_COMPANY && isSingleMemberLLC
        ? "Single Member LLC"
        : legalEntityDisplayNames[businessLegalEntityType]
    }
  />
);

export const IsUnsupportedBusinessType = () => (
  <LineItem
    id="item_isUnsupportedBusinessType"
    title="Is your business account for an association, the Boys Scouts of America, the Girl Scouts of America...?"
    selector={({ isUnsupportedBusinessType }) => (isUnsupportedBusinessType ? "Yes" : "No")}
  />
);

export const IsTrust = () => (
  <LineItem
    id="item_isTrust"
    title="Is your business formed as a trust?"
    selector={({ isTrust }) => (isTrust ? "Yes" : "No")}
  />
);

const prefillLegalEntityDisplayNames = {
  [SOLE_PROPRIETORSHIP]: convertLegalEntityEnumToDisplay(SOLE_PROPRIETORSHIP),
  [LIMITED_LIABILITY_COMPANY]: "Limited Liability Company",
  ...partnershipTypesDisplay,
  [C_CORPORATION]: businessTaxClassificationDisplay[C_CORPORATION],
  [S_CORPORATION]: businessTaxClassificationDisplay[S_CORPORATION],
};

// similar to LegalEntityType but has caveat that it displays LLC generally for prefilled businesses since SMLLC vs MMLLC is deduced later in flow
export const PrefillLegalEntityType = () => (
  <LineItem
    id="item_prefillCompanyType"
    title="Company Type"
    selector={({ businessLegalEntityType }) => prefillLegalEntityDisplayNames[businessLegalEntityType]}
  />
);

export const IsSingleMemberLLC = () => (
  <LineItem
    id="item_isSingleMemberLLC"
    title="Are you a single member LLC?"
    selector={({ isSingleMemberLLC }) => (isSingleMemberLLC ? "Yes" : "No")}
  />
);

export const BusinessTaxId = () => {
  const businessTaxIdType = useStore(state => state.businessTaxIdType);
  return (
    <LineItem
      id="item_businessTaxId"
      title={`Tax ID Number (${businessTaxIdType})`}
      selector={({ businessTaxId }) =>
        businessTaxIdType === SSN ? formatSSN(businessTaxId) : formatEIN(businessTaxId)
      }
      mask={businessTaxIdType === SSN ? "•••-••-" : "••-•••"}
      maskButtonAriaLabel="toggle company type tax ID number view"
    />
  );
};

export const LegalBusinessName = () => (
  <LineItem
    id="item_legalBusinessName"
    title="Legal Business Name"
    selector={({ businessLegalName }) => businessLegalName}
  />
);

export const TaxClassification = () => (
  <LineItem
    id="item_taxClassification"
    title="Tax Classification"
    selector={({ businessTaxClassification }) => businessTaxClassificationDisplay[businessTaxClassification]}
  />
);

export const MajorityOwnership = () => (
  <LineItem
    id="item_majorityOwnership"
    title="Majority Ownership Type"
    selector={({ businessOwnershipType }) => ownershipTypesDisplay[businessOwnershipType]}
  />
);

export const AccountType = () => (
  <LineItem
    id="item_bankAccountType"
    title="Bank Account Type(s)"
    selector={({ applicationProductsSelected }) =>
      applicationProductsSelected?.map(({ productType }) => (
        <div key={productType}>{convertProductTypeEnum(productType)}</div>
      ))
    }
  />
);

export const ApplicantFullName = () => (
  <LineItem
    id="item_applicantName"
    title="First & Last Name"
    selector={({ applicantFirstName, applicantLastName }) => `${applicantFirstName} ${applicantLastName}`}
  />
);

export const ApplicantDateOfBirth = () => {
  const isApplicantPrefilled = useStore(state => state.isApplicantPrefilled);
  return (
    <LineItem
      id="item_applicantDateOfBirth"
      title={isApplicantPrefilled ? "Date of Birth (MM/DD)" : "Date of Birth (MM/DD/YYYY)"}
      selector={
        isApplicantPrefilled
          ? ({ applicantDateOfBirthPartial }) => `${applicantDateOfBirthPartial?.replace("-", "/")}/••••`
          : ({ applicantBirthDate }) => applicantBirthDate
      }
    />
  );
};

export const ApplicantSSN = () => (
  <LineItem
    id="item_applicantSSN"
    title="Social Security Number"
    selector={({ applicantTaxId }) => formatSSN(applicantTaxId)}
    mask="•••-••-"
    maskButtonAriaLabel="toggle personal information SSN view"
  />
);

export const ApplicantCitizenshipInfo = () => {
  const [isUSCitizen, secondaryCitizenship] = useStore(
    state => [state.applicantCitizenshipStatus === US_CITIZEN, state.applicantCountrySecondaryCitizenship],
    shallow
  );

  return (
    <>
      <LineItem
        id="item_applicantCitizenship"
        title="Citizenship Status"
        selector={({ applicantCitizenshipStatus }) => citizenshipTypeDisplay[applicantCitizenshipStatus]}
      />
      {!isUSCitizen && (
        <LineItem
          id="item_applicantPrimaryCountryOfCitizenship"
          title="Country of Citizenship"
          selector={({ applicantCountryPrimaryCitizenship }) =>
            getCountryName(applicantCountryPrimaryCitizenship)
          }
        />
      )}
      {!isUSCitizen && secondaryCitizenship !== PLACEHOLDER_NONE && (
        <StaticLineItem
          id="item_applicantSecondaryCountryOfCitizenship"
          title="Country of Citizenship"
          value={getCountryName(secondaryCitizenship)}
        />
      )}
    </>
  );
};

export const ApplicantStreetAddress = () => (
  <LineItem
    id="item_applicantStreetAddress"
    title="Residential Street Address"
    selector={state =>
      combineParts([
        state.applicantAddress1,
        state.applicantAddress2,
        state.applicantAddressCity,
        state.applicantAddressState,
        state.applicantAddressPostalCode,
      ])
    }
  />
);

export const ApplicantMobilePhoneNumber = () => {
  const isApplicantPrefilled = useStore(state => state.isApplicantPrefilled);

  return (
    <LineItem
      id="item_applicantMobilePhone"
      title={isApplicantPrefilled ? "Last 4 Digits of Mobile Phone Number" : "Mobile Phone Number"}
      selector={({ applicantMobilePhone, applicantMobilePhonePartial }) =>
        isApplicantPrefilled
          ? `(•••)•••-${applicantMobilePhonePartial}`
          : formatPhoneNumber(applicantMobilePhone)
      }
    />
  );
};

export const ApplicantHomePhoneNumber = () => {
  const isApplicantPrefilled = useStore(state => state.isApplicantPrefilled);

  return (
    <LineItem
      id="item_applicantHomePhone"
      title={isApplicantPrefilled ? "Last 4 Digits of Home Phone Number" : "Home Phone Number"}
      selector={({
        applicantIsMobilePhoneSameAsHome,
        applicantMobilePhone,
        applicantHomePhone,
        applicantHomePhonePartial,
      }) =>
        // eslint-disable-next-line no-nested-ternary
        isApplicantPrefilled
          ? `(•••)•••-${applicantHomePhonePartial}`
          : applicantIsMobilePhoneSameAsHome
          ? formatPhoneNumber(applicantMobilePhone)
          : formatPhoneNumber(applicantHomePhone)
      }
    />
  );
};

export const ApplicantEmailAddress = () => (
  <LineItem
    id="item_applicantEmail"
    title="Personal Email Address"
    selector={({ applicantEmailAddress }) => applicantEmailAddress}
  />
);

export const DoingBusinessAsName = () => {
  const doingBusinessName = useStore(state => state.businessDoingBusinessAsName);

  return doingBusinessName ? (
    <StaticLineItem
      id="item_doingBusinessAsName"
      title="Doing Business As (DBA) Name"
      value={doingBusinessName}
    />
  ) : (
    <></>
  );
};

export const BusinessIndustry = () => (
  <LineItem
    id="item_businessIndustry"
    title="Business Industry"
    selector={({ businessIndustryDescription }) => businessIndustryDescription}
  />
);

export const AnnualRevenueRange = () => (
  <LineItem
    id="item_annualRevenue"
    title="Annual Revenue"
    selector={({ businessAnnualRevenueRange }) =>
      annualBusinessRevenueRangeDisplay[businessAnnualRevenueRange]
    }
  />
);

export const AccountPurposes = () => {
  const storedAccountPurpose = useStore(state => state.accountPurpose);
  return (
    <>
      {Object.entries(storedAccountPurpose ?? {}).map(([key, value], index) => (
        <StaticLineItem
          id={`item_accountPurpose${index}`}
          key={key}
          title={`How do you plan to use this ${convertProductTypeEnum(key)}® bank account?`}
          value={accountPurposeDisplay[value]}
        />
      ))}
    </>
  );
};

export const ForeignBusinessInfo = () => {
  const isBusinessOutsideUS = useStore(state => state.isBusinessHeadquarteredOutsideUS);

  return (
    <>
      <StaticLineItem
        id="item_businessOutsideUS"
        title="Is your business headquartered, registered or primarily operating outside the U.S.?"
        value={isBusinessOutsideUS ? "Yes" : "No"}
      />
      {isBusinessOutsideUS && (
        <>
          <LineItem
            id="item_countryOfHeadquarters"
            title="Country of Business Headquarters"
            selector={({ businessHeadquarterCountry }) => getCountryName(businessHeadquarterCountry)}
          />
          <LineItem
            id="item_countryOfRegistration"
            title="Country of Registration or Incorporation"
            selector={({ businessCountryOfRegistrationOrIncorporation }) =>
              getCountryName(businessCountryOfRegistrationOrIncorporation)
            }
          />
          <LineItem
            id="item_countryOfOperation"
            title="Country of Primary Operation"
            selector={({ businessCountryOfPrimaryOperations }) =>
              getCountryName(businessCountryOfPrimaryOperations)
            }
          />
        </>
      )}
    </>
  );
};

export const CharitableBusinessInfo = () => {
  const [isCharitable, purpose] = useStore(
    state => [state.isCharitableOrganization, state.businessPurposeOfOrganizationCategory],
    shallow
  );

  return (
    <>
      <StaticLineItem
        id="item_businessCharitable"
        title="Is your business a charitable organization?"
        value={isCharitable ? "Yes" : "No"}
      />
      {isCharitable && (
        <>
          <StaticLineItem
            id="item_purposeOfOrganization"
            title="What is the purpose of the organization"
            value={organizationTypeDisplayNames[purpose]}
          />
          {purpose === OTHER && (
            <LineItem
              id="item_purposeOther"
              title="Describe the purpose of your organization."
              selector={({ businessPurposeOfOrganizationOtherDescription }) =>
                businessPurposeOfOrganizationOtherDescription
              }
            />
          )}
          <LineItem
            id="item_internationalActivity"
            title="Do you expect repeat international activity?"
            selector={({ doesBusinessExpectRepeatedInternationalActivity }) =>
              doesBusinessExpectRepeatedInternationalActivity ? "Yes" : "No"
            }
          />
        </>
      )}
    </>
  );
};

export const BusinessNonProfitInfo = () => (
  <LineItem
    id="item_isNonProfit"
    title="Are you a non-profit organization?"
    selector={({ isNonProfit }) => (isNonProfit ? "Yes" : "No")}
  />
);

export const BusinessStreetAddress = () => (
  <LineItem
    id="item_businessStreetAddress"
    title="Business Street Address"
    selector={state =>
      combineParts([
        state.businessAddress1,
        state.businessAddress2,
        state.businessAddressCity,
        state.businessAddressState,
        state.businessAddressPostalCode,
      ])
    }
  />
);

export const IsBusinessAddressTheHomeAddress = () => (
  <LineItem
    id="item_isBusinessAddressTheHomeAddress"
    title="Are you operating your business from home?"
    selector={({ isBusinessAddressTheHomeAddress }) => (isBusinessAddressTheHomeAddress ? "Yes" : "No")}
  />
);

export const BusinessMailingAddress = () => {
  const isDifferentMailing = useStore(state => state.doesBusinessUseDifferentMailingAddress);

  return isDifferentMailing ? (
    <LineItem
      id="item_businessMailingAddress"
      title="Business Mailing Address"
      selector={state =>
        combineParts([
          state.businessMailingAddress1,
          state.businessMailingAddress2,
          state.businessMailingAddressCity,
          state.businessMailingAddressState,
          state.businessMailingAddressPostalCode,
        ])
      }
    />
  ) : (
    <StaticLineItem
      id="item_sameMailing"
      title="Same Mailing Address as Business Street Address?"
      value="Yes"
    />
  );
};

export const BusinessPhoneNumber = () => (
  <LineItem
    id="item_businessPhone"
    title="Business Phone Number"
    selector={({ businessPrimaryPhone }) => formatPhoneNumber(businessPrimaryPhone)}
  />
);

export const BusinessEmailAddress = () => (
  <LineItem
    id="item_businessEmail"
    title="Business Email Address"
    selector={({ businessPrimaryEmail }) => businessPrimaryEmail}
  />
);

export const BusinessWebAddress = () => {
  const webAddress = useStore(state => state.businessWebAddress);

  return webAddress ? (
    <StaticLineItem id="item_businessWebAddress" title="Business Web Address (Optional)" value={webAddress} />
  ) : (
    <></>
  );
};

const BusinessActivitiesModalLink = styled.a.attrs({
  role: "button",
  tabIndex: "0",
})`
  cursor: pointer;
`;

const flattenBulletedHRBRQuestion = question => {
  const questionParsed = document.createElement("div");
  questionParsed.innerHTML = question;
  questionParsed.querySelectorAll("p:not(:first-of-type)").forEach(p => questionParsed.removeChild(p));
  const paragraph = questionParsed.querySelector("p");

  if (paragraph && paragraph.textContent.split("• ")[0] === "") {
    // Special formatting strictly for bulleted lists - if the paragraph has bullets, the first element in the array from split is an empty string
    // Modify textContent because the tags must stay intact for the final innerHTML extraction
    paragraph.textContent = paragraph.textContent
      .split("• ")
      .slice(1)
      .map(sentence => sentence.charAt(0).toLowerCase() + sentence.slice(1));
    // Make all commas have a space after them and add a question mark
    paragraph.textContent = paragraph.textContent.replaceAll(",", ", ");
  }
  return questionParsed.innerHTML.replaceAll(/(\r\n|\n|\r)/gm, " ").replaceAll(":", ": ");
};

const getHRBRQuestionForDisplay = question => {
  const reviewQuestionParsed = document.createElement("div");
  const reviewQuestion = flattenBulletedHRBRQuestion(question);
  reviewQuestionParsed.innerHTML = reviewQuestion;
  // Identify if the question exceeds the character limit, so that they are displayed as shortened on the review page.
  // the regex will match up to 150 characters, followed by a whitespace - so, the match ends on a whitespace character or at the end of the string
  // this will prevent a word from being cut off. We take the first string that the regex has matched and use it as a shortened question
  return reviewQuestionParsed.textContent.length > 150
    ? `${reviewQuestionParsed.textContent.match(/.{1,150}(?:\s|$)/g)[0].slice(0, -1)}...?`
    : reviewQuestionParsed.textContent;
};

const ModalContainer = styled.div.attrs({
  className: "grv-dialog__container",
})`
  /* Specific width to match other informational dialogs, gravity-core CSS lacks ability to control this otherwise */
  max-width: 740px;

  .grv-dialog__content {
    overflow-y: scroll;
  }

  /* Hide any line break elements from Chariot content */
  br {
    display: none;
  }
`;

export const RiskyActivities = ({
  noActivitiesTitle = "Is your business involved in any of the activities indicated?",
  noActivities = "No",
  seeAllActivitiesLinkText = "See All Activities",
}) => {
  const [riskyBusinessActivitiesQuestions, hrbrActivities] = useStore(state => [
    state.content
      .map(bundle => bundle?.riskyProhibitedActivitiesQuestions ?? null)
      .find(bundle => bundle !== null),
    state.hrbrActivities,
  ]);

  // Organize riskyBusinessActivitiesQuestions content into key/value pairs of {enum: question}
  const allQuestionsByEnum = useMemo(
    () =>
      Object.values(riskyBusinessActivitiesQuestions)
        .flatMap(question => Object.entries(question))
        .reduce(
          (result, [questionEnum, question]) => ({
            ...result,
            [questionEnum]: question,
          }),
          {}
        ),
    [riskyBusinessActivitiesQuestions]
  );

  const questionsToDisplay = useMemo(() => {
    // Filtering by hrbrActivities guarantees that as long as the hrbrActivities key/value pairs are organized by the order they appear in the UI,
    // they will also appear in that desired order on this review page section
    const toDisplay = Object.entries(hrbrActivities).reduce((acc, [businessHasKey, value]) => {
      if (value) {
        const enumKey =
          ALL_STANDARD_LOGIC_HRBR_ENUMS[businessHasKey] || ALL_SPECIAL_LOGIC_HRBR_ENUMS[businessHasKey];
        acc[businessHasKey] = allQuestionsByEnum[enumKey];
      }
      return acc;
    }, {});
    return toDisplay;
  }, [hrbrActivities, allQuestionsByEnum]);

  const totalActivitiesSelected = useMemo(
    () => Object.entries(questionsToDisplay).length,
    [questionsToDisplay]
  );

  const [seeAllActivities, showAllActivitiesModal] = useState(false);
  const handleSeeAllActivitiesClick = useCallback(() => showAllActivitiesModal(true), []);
  const onSeeAllActivitiesOk = useCallback(() => showAllActivitiesModal(false), []);

  // Only display a maximum of 5 selected activities on the review page
  // If there are MORE THAN 5 selected activities, display the first 5 and the See All Activities link
  const reviewPageQuestionsToDisplay = useMemo(
    () => Object.entries(questionsToDisplay).slice(0, 5),
    [questionsToDisplay]
  );

  const seeAllButtonId = useButtonId("seeAllActivities");

  const dialogHeadingId = "applicationReview_businessActivities_dialog_heading";
  const dialogDescriptionId = "applicationReview_businessActivities_dialog_content";
  const dialogCloseButtonId = useButtonId("businessActivitiesModalClose");

  return (
    <>
      {reviewPageQuestionsToDisplay.map(([businessHasKey, question]) => (
        <StaticLineItem
          id={`item_businessActivities_${businessHasKey}`}
          key={businessHasKey}
          title={
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: SPECIAL_DISPLAY_QUESTIONS[businessHasKey] || getHRBRQuestionForDisplay(question),
              }}
            />
          }
          value={
            SPECIAL_DISPLAY_QUESTIONS[businessHasKey]
              ? jewelryRevenueRangeDisplay[hrbrActivities[businessHasKey]]
              : "Yes"
          }
        />
      ))}
      {totalActivitiesSelected > 5 && (
        <>
          <div className="grv-margin__bottom--medium-1">
            <BusinessActivitiesModalLink
              className="grv-text--small-2 grv-weight--semibold grv-background--transparent grv-color--interaction-blue-50"
              onClick={handleSeeAllActivitiesClick}
              onKeyPress={event => event.key === "Enter" && handleSeeAllActivitiesClick()}
              id={seeAllButtonId}
            >
              {`${seeAllActivitiesLinkText} (${totalActivitiesSelected})`}
            </BusinessActivitiesModalLink>
          </div>
          <ReactModal
            ariaHideApp
            preventScroll
            shouldFocusAfterRender
            shouldReturnFocusAfterClose
            aria={{
              labelledby: dialogHeadingId,
              describedby: dialogDescriptionId,
            }}
            bodyOpenClassName={null}
            className="grv-dialog grv-dialog--active grv-dialog--large"
            contentLabel="Business Activities"
            htmlOpenClassName={null}
            id="businessActivities-explanation-modal"
            isOpen={seeAllActivities}
            overlayClassName="grv-dialog__overlay grv-dialog__overlay--dark"
            portalClassName="grv-portal"
            role="dialog"
          >
            <ModalContainer>
              <h1
                id={dialogHeadingId}
                className="grv-dialog__heading grv-text__heading--large grv-margin__top--medium-1 grv-margin__bottom--medium-1"
              >
                Business Activities
              </h1>
              <button
                aria-label="close business activities modal"
                id={dialogCloseButtonId}
                className="grv-dialog__close"
                type="button"
                onClick={onSeeAllActivitiesOk}
              />
              <h2
                id="businessActivities-dialog-subheading"
                className="grv-text__heading--medium grv-margin__top--small-2 grv-margin__bottom--large-1"
              >
                You&apos;ve selected your business does the following activities:
              </h2>
              <div id={dialogDescriptionId} className="grv-dialog__content">
                <ul className="grv-padding__left--medium-2 grv-padding__right--medium-2 grv-margin--none">
                  {Object.entries(questionsToDisplay).map(([businessHasKey, question]) => (
                    <li
                      key={businessHasKey}
                      id={`item_allBusinessActivities_${businessHasKey}`}
                      className="grv-text--medium-1 grv-margin__bottom--medium-2 grv-padding__left--small-2"
                    >
                      <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: flattenBulletedHRBRQuestion(question),
                        }}
                      />
                      {SPECIAL_DISPLAY_QUESTIONS[businessHasKey] && (
                        <div className="grv-margin__top--small-2 grv-margin__bottom--none">
                          {jewelryRevenueRangeDisplay[hrbrActivities[businessHasKey]]}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </ModalContainer>
          </ReactModal>
        </>
      )}
      {totalActivitiesSelected === 0 && (
        <StaticLineItem id="item_businessActivities_None" title={noActivitiesTitle} value={noActivities} />
      )}
    </>
  );
};

export const IsApplicantOwner = () => (
  <LineItem
    id="item_applicantIsOwner"
    title="Are you an owner?"
    selector={({ isApplicantBusinessOwner }) => (isApplicantBusinessOwner ? "Yes" : "No")}
  />
);

export const ApplicantTitle = () => (
  <LineItem
    id="item_applicantTitle"
    title="Title"
    selector={({ businessControllerType }) => businessControllerTypeDisplay[businessControllerType]}
  />
);

export const OtherBeneficialOwners = () => (
  <LineItem
    id="item_otherOwners"
    title="Do you have other owners that own 25% or more of the business?"
    selector={({ hasOtherBeneficialOwners }) => (hasOtherBeneficialOwners ? "Yes" : "No")}
  />
);

export const ApplicantOwnershipPercentage = () => {
  const isApplicantBusinessOwner = useStore(state => state.isApplicantBusinessOwner);
  return isApplicantBusinessOwner ? (
    <LineItem
      id="item_applicantOwnershipPercentage"
      title="Percentage Ownership"
      selector={({ applicantOwnershipPercentage }) => `${applicantOwnershipPercentage}%`}
    />
  ) : (
    <></>
  );
};

export const CustomerType = () => {
  const encourageExpandedCustomerSignInEnabled = useStore(state =>
    isFeatureEnabled(state, "encourageExpandedCustomerSignIn")
  );
  const applicationChannel = useApplicationChannel();
  const encourageSignOnEnabled = encourageExpandedCustomerSignInEnabled && applicationChannel === SBB_WEB;
  return (
    <LineItem
      id="item_customerType"
      title={
        encourageSignOnEnabled
          ? "Do you have a Capital One account?"
          : "Do you have a small business bank account with us?"
      }
      selector={({ isApplicantAuthenticated }) =>
        // note this is not based on prefill - per design, we want this to reflect customers that were
        // unsure of their status and may have been authenticated and routed back into the new flow
        // (e.g., customers with non-SBB accounts)
        isApplicantAuthenticated ? "Yes, I have an account or I'm unsure" : "No, I don't have an account"
      }
    />
  );
};

export const AreStateDocsFiled = () => {
  const areStateDocsFiled = useStore(state => state.areStateDocsFiled);
  return areStateDocsFiled !== null ? (
    <StaticLineItem
      id="item_areStateDocsFiled"
      title="Did you file documents with the state to establish your business (e.g., Articles of Organization, Certificate of Formation, Articles of Incorporation...?"
      value={areStateDocsFiled ? "Yes" : "No"}
    />
  ) : (
    <></>
  );
};

export const HasMultipleBusinessOwners = () => {
  const hasMultipleBusinessOwners = useStore(state => state.hasMultipleBusinessOwners);
  return hasMultipleBusinessOwners !== null ? (
    <StaticLineItem
      id="item_hasMultipleBusinessOwners"
      title="Does your business have multiple business owners?"
      value={hasMultipleBusinessOwners ? "Yes" : "No"}
    />
  ) : (
    <></>
  );
};

export const PromoPill = styled.div`
  /* pill styling */
  display: inline-block;
  padding: var(--grv-size-spacing-small-1) var(--grv-size-spacing-medium-1);
  background-color: var(--grv-color-white);

  /* text styling */
  color: var(--grv-color-interaction-green-50);
  font-size: var(--grv-size-font-small-1);
  font-weight: var(--grv-font-weight-semibold);
  line-height: var(--grv-font-line-height-normal);
  text-align: center;

  /* border styling */
  border: var(--grv-size-border-width-1);
  border-style: solid;
  border-radius: var(--grv-size-border-radius-circle);
  border-color: var(--grv-color-interaction-green-50);
`;
