import ReactModal from "react-modal";
import styled from "styled-components";
import combineStrings from "../../../utils/combineStrings";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const ModalContainer = styled.div.attrs({
  className: "grv-dialog__container",
})`
  /* Specific width from design, gravity-core CSS lacks ability to control this otherwise */
  max-width: 740px;

  .grv-dialog__content {
    overflow-y: scroll;
  }
`;

const ExplanationModal = ({
  active,
  idBase,
  headingText,
  onOk,
  className = "",
  buttonClassName = "",
  children,
  ...rest
}) => {
  const headingId = `${idBase}-dialog-heading`;
  const descriptionId = `${idBase}-dialog-content`;

  return (
    <ReactModal
      ariaHideApp
      preventScroll
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      aria={{
        labelledby: headingId,
        describedby: descriptionId,
      }}
      bodyOpenClassName={null}
      className={combineStrings("grv-dialog grv-dialog--active", className)}
      contentLabel={headingText}
      htmlOpenClassName={null}
      id={`${idBase}-explanation-modal`}
      isOpen={active}
      overlayClassName="grv-dialog__overlay grv-dialog__overlay--dark"
      portalClassName="grv-portal"
      role="dialog"
      {...rest}
    >
      <ModalContainer>
        <h1
          id={headingId}
          className="grv-dialog__heading grv-text__heading--large grv-margin__top--medium-1 grv-margin__bottom--medium-1"
        >
          {headingText}
        </h1>
        <button
          aria-label={`close ${headingText} modal`}
          id={useButtonId(`${idBase}ModalClose`)}
          className="grv-dialog__close"
          type="button"
          onClick={onOk}
        />
        <div className="grv-dialog__content" id={descriptionId}>
          {children}
        </div>
        <div className="grv-dialog__buttons">
          <button
            id={useButtonId(`${idBase}ModalAcknowledge`)}
            className={combineStrings("grv-button", buttonClassName)}
            type="button"
            onClick={onOk}
          >
            Got it
          </button>
        </div>
      </ModalContainer>
    </ReactModal>
  );
};

export default ExplanationModal;
