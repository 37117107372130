import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import shallow from "zustand/shallow";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import useStore from "../../../store/store";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import {
  AddressFields,
  AddressInformationTooltip,
  addressSchema,
  useAddressValidation,
} from "../../shared/Address";
import Heading from "../../shared/Heading";
import { CenteredRow, Col, Grid, Row } from "../../shared/Layout";
import NavigationButtons from "../../shared/NavigationButtons";
import Page from "../../shared/Page";
import SectionHeader from "../../shared/SectionHeader";
import TextInput from "../../shared/TextInput";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import { useNavigateUnlessReview } from "../../../utils/reviewContext";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useRouting from "../../../utils/hooks/useRouting";
import PhoneNumberField from "../../shared/PhoneNumberField";
import { emailSchema, validateEmail } from "../../../utils/emailValidation";
import { getPhoneNumberSchema } from "../../../utils/phoneNumberValidation";
import { CitizenshipFields, citizenshipSchema } from "../../shared/Citizenship";
import { isFeatureEnabled } from "../../../utils/configSelector";
import { BooleanRadioListField } from "../../shared/RadioList";
import { useInputId, useLinkId } from "../../../utils/hooks/usePageScopedId";
import {
  FootnoteReference,
  GridWithFootnotes,
  FootnoteReferenceWithSpecificNumber,
} from "../../shared/Footnotes";
import ContactInfoDisclosures, {
  EMAIL_ADDRESS_DISCLOSURE_FOOTNOTE_ID,
  PHONE_NUMBER_DISCLOSURE_FOOTNOTE_ID,
} from "../../shared/ContactInfoDisclosures";

const mobilePhoneSchema = getPhoneNumberSchema({
  required: "Enter your mobile phone number",
  invalid: "Enter a valid 10-digit mobile phone number",
});

const homePhoneSchema = getPhoneNumberSchema({
  required: "Enter your home phone number",
  invalid: "Enter a valid 10-digit home phone number",
});

const contactInfoSchema = yup.object().shape({
  address: addressSchema,
  ...citizenshipSchema,
  mobilePhone: mobilePhoneSchema,
  isMobilePhoneSameAsHome: yup.boolean().nullable().required("Select a response"),
  homePhone: yup.mixed().when(["isMobilePhoneSameAsHome"], {
    is: false,
    then: () => homePhoneSchema,
    otherwise: () => yup.mixed(), // allow anything, prevents null issue
  }),
  email: emailSchema,
});

const PhoneMatchErrorText = () => (
  <>
    This phone number matches your mobile phone number. Select “Yes” to confirm these are the same{" "}
    <u>
      <strong>or</strong>
    </u>{" "}
    update your home phone number.
  </>
);

const PersonalContactInfo = () => {
  usePageTitle("Personal Contact Information");
  usePreventNavigation();
  const headingRef = useFocusHeading();
  const [
    applicationReferenceId,
    storedAddressLine1,
    storedAddressLine2,
    storedAddressCity,
    storedAddressState,
    storedAddressZip,
    storedMobilePhone,
    storedHomePhone,
    storedIsMobileSameAsHome,
    storedEmail,
    storedApplicantCitizenshipStatus,
    storedApplicantCountryPrimaryCitizenship,
    storedApplicantCountrySecondaryCitizenship,
    newVerificationServiceEnabled,
    submitPersonalContactInfo,
  ] = useStore(
    state => [
      state.applicationReferenceId,
      state.applicantAddress1,
      state.applicantAddress2,
      state.applicantAddressCity,
      state.applicantAddressState,
      state.applicantAddressPostalCode,
      state.applicantMobilePhone,
      state.applicantHomePhone,
      state.applicantIsMobilePhoneSameAsHome,
      state.applicantEmailAddress,
      state.applicantCitizenshipStatus,
      state.applicantCountryPrimaryCitizenship,
      state.applicantCountrySecondaryCitizenship,
      isFeatureEnabled(state, "newVerificationServiceEnabled"),
      state.pageSubmit.submitPersonalContactInfo,
    ],
    shallow
  );
  const formMethods = useForm({
    resolver: yupResolver(contactInfoSchema),
    shouldFocusError: false,
    defaultValues: {
      address: {
        addressLine1: storedAddressLine1 || "",
        addressLine2: storedAddressLine2 || "",
        city: storedAddressCity || "",
        state: storedAddressState || PLACEHOLDER_NONE,
        zip: storedAddressZip || "",
      },
      mobilePhone: storedMobilePhone || "",
      homePhone: storedHomePhone || "",
      isMobilePhoneSameAsHome: storedIsMobileSameAsHome, // allow null so the page starts with neither selected
      email: storedEmail || "",
      citizenshipStatus: storedApplicantCitizenshipStatus || PLACEHOLDER_NONE,
      primaryCitizenship: storedApplicantCountryPrimaryCitizenship || PLACEHOLDER_NONE,
      secondaryCitizenship: storedApplicantCountrySecondaryCitizenship || PLACEHOLDER_NONE,
    },
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted },
    setError,
    getValues,
    watch,
    trigger,
  } = formMethods;
  const navigate = useNavigateUnlessReview();
  const [backRoute, nextRoute] = useRouting();
  const [addressValidationState, validateAddress] = useAddressValidation({
    setError,
    getValues,
    updateRegion: true,
  });
  const onSubmit = useCallback(
    async ({
      address,
      mobilePhone,
      homePhone,
      isMobilePhoneSameAsHome,
      email,
      citizenshipStatus,
      primaryCitizenship,
      secondaryCitizenship,
    }) => {
      if (!isMobilePhoneSameAsHome && mobilePhone === homePhone) {
        setError("isMobilePhoneSameAsHome", {
          type: "validation",
          message: <PhoneMatchErrorText />,
        });
        return;
      }
      const [{ isAddressValid, isAddressAcceptable }, isEmailAddressValid] = await Promise.all([
        validateAddress(),
        validateEmail({
          applicationReferenceId,
          email,
          setError,
          newVerificationServiceEnabled,
        }),
      ]);

      if (isAddressAcceptable && isEmailAddressValid) {
        await submitPersonalContactInfo({
          isAddressValid,
          address,
          mobilePhone,
          homePhone,
          isMobilePhoneSameAsHome,
          email,
          citizenshipStatus,
          primaryCitizenship,
          secondaryCitizenship,
        });
        navigate(nextRoute);
      }
    },
    [
      validateAddress,
      nextRoute,
      setError,
      applicationReferenceId,
      submitPersonalContactInfo,
      navigate,
      newVerificationServiceEnabled,
    ]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(onSubmit);

  // reset the mobile/home equality check error message whenever the numbers are changed
  // note this does NOT retrigger that comparison, since that is in the submit logic
  // that logic cannot be pulled into the yup validations (cleanly) due to cyclic dependencies
  const onNumberChanged = useCallback(() => {
    if (isSubmitted) {
      trigger("isMobilePhoneSameAsHome");
    }
  }, [trigger, isSubmitted]);

  // check against false specifically - null should still hide the field
  const showHomePhone = watch("isMobilePhoneSameAsHome") === false;

  const emailFieldId = useInputId("email");

  const mobileNumberFootnoteReferenceId = useLinkId("mobileNumberFootnoteReference");
  const homeNumberFootnoteReferenceId = useLinkId("homeNumberFootnoteReference");
  const emailAddressFootnoteReferenceId = useLinkId("emailAddressFootnoteReference");

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step="SECTION 1 OF 4"
          mainHeading="Where can we reach you?"
          subHeading="To complete your application, we need some contact info."
        />
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            <SectionHeader title="Personal contact information" />
          </Col>
        </CenteredRow>
      </Grid>
      <FormProvider {...formMethods}>
        <form autoComplete="off" onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
          <AddressFields
            baseField="address"
            idBase="personalAddress"
            addressLine1Label={
              <span>
                Residential Street Address <AddressInformationTooltip id="personal-address-tooltip" />
              </span>
            }
            addressLine1Helper={`
              Use the address where you physically live. Submitting an unsupported address will result in
              delays in the application process and/or a decline decision.
            `}
            addressLine1DescribedBy="personal-address-tooltip"
            {...addressValidationState}
          />
          <GridWithFootnotes>
            <Row>
              <Col lg={2} md={2} offset={{ lg: 2 }} sm={2}>
                <PhoneNumberField
                  label="Mobile Phone Number"
                  helper={
                    <FootnoteReference
                      to={PHONE_NUMBER_DISCLOSURE_FOOTNOTE_ID}
                      id={mobileNumberFootnoteReferenceId}
                    >
                      See footnote for details
                    </FootnoteReference>
                  }
                  control={control}
                  name="mobilePhone"
                  onChange={onNumberChanged}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
                <BooleanRadioListField
                  control={control}
                  name="isMobilePhoneSameAsHome"
                  legendText="Is your mobile phone number the same as your home phone number?"
                />
              </Col>
            </Row>
            {showHomePhone && (
              <Row>
                <Col lg={2} md={2} offset={{ lg: 2 }} sm={2}>
                  <PhoneNumberField
                    label="Home Phone Number"
                    helper={
                      <FootnoteReferenceWithSpecificNumber
                        $number={1}
                        to={PHONE_NUMBER_DISCLOSURE_FOOTNOTE_ID}
                        id={homeNumberFootnoteReferenceId}
                      >
                        See footnote for details
                      </FootnoteReferenceWithSpecificNumber>
                    }
                    control={control}
                    name="homePhone"
                    onChange={onNumberChanged}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={3} md={3} offset={{ lg: 2 }} sm={4}>
                <TextInput
                  id={emailFieldId}
                  name="email"
                  type="email"
                  label="Personal Email Address"
                  helper={
                    <FootnoteReference
                      to={EMAIL_ADDRESS_DISCLOSURE_FOOTNOTE_ID}
                      id={emailAddressFootnoteReferenceId}
                    >
                      Updates regarding this application will be sent to your personal email address. See
                      footnote for details.
                    </FootnoteReference>
                  }
                  error={errors?.email?.message}
                  {...register("email")}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={8} sm={4}>
                <CitizenshipFields />
              </Col>
            </Row>
          </GridWithFootnotes>
          <ContactInfoDisclosures />
          <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
        </form>
      </FormProvider>
    </Page>
  );
};

export default PersonalContactInfo;
