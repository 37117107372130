import styled from "styled-components";
import { Col, Grid, Row } from "../Layout";
import mediaQuery from "../../../utils/mediaQuery";
import Footnotes from "../Footnotes";

const FootnotesSection = styled(Grid).attrs({
  className: "grv-text--small-1 grv-margin__bottom--medium-2",
})`
  /* Extra margin on non-mobile */
  @media (${mediaQuery.medium}) {
    margin-top: var(--grv-size-spacing-medium-2);
    margin-bottom: var(--grv-size-spacing-large-3);
  }

  /* Reduce spacing for footnote list indicators */
  li::before {
    padding-right: 0.5ch;
  }
`;

// No need for page-scoped IDs because they are just text elements
export const PHONE_NUMBER_DISCLOSURE_FOOTNOTE_ID = "phoneNumberDisclosure";
export const EMAIL_ADDRESS_DISCLOSURE_FOOTNOTE_ID = "emailAddressDisclosure";

const ContactInfoDisclosures = () => (
  <FootnotesSection>
    <Row>
      <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
        <Footnotes>
          <li id={PHONE_NUMBER_DISCLOSURE_FOOTNOTE_ID}>
            By providing your phone number, you are giving consent and permission to be contacted about all of
            your relationships with Capital One and companies working on its behalf. Your consent allows the
            use of text messages, artificial or prerecorded voice messages and automatic dialing technology
            for informational and account servicing. Message and data rates may apply.
          </li>
          <li id={EMAIL_ADDRESS_DISCLOSURE_FOOTNOTE_ID}>
            When you provide your email address, we may use it to send you important information about your
            application and account(s), as well as other useful products and services.
          </li>
        </Footnotes>
      </Col>
    </Row>
  </FootnotesSection>
);

export default ContactInfoDisclosures;
