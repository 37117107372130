import ExplanationModal from "./ExplanationModal";

const FactaReportingModal = ({ active, onOk }) => (
  <ExplanationModal
    {...{ active, onOk }}
    idBase="facta-reporting"
    headingText="FATCA Reporting"
    className="grv-dialog--large"
    shouldCloseOnEsc={false}
  >
    <p className="grv-text__heading--normal grv-weight--normal grv-margin__bottom--medium-1">
      FATCA stands for Foreign Account Tax Compliance Act. This act requires certain U.S. persons to report
      information about foreign financial assets and accounts. This certification does not apply to this
      particular account with us.
    </p>
  </ExplanationModal>
);

export default FactaReportingModal;
