import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { CenteredRow, Grid, Col, Row } from "../Layout";
import Page, { ContentContainer } from "../Page";
import settings from "../../../assets/settings.svg";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import getContentReader from "../../../utils/getContentReader";
import CEMPSurvey from "../CEMPSurvey";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";
import Icon from "../Icon/Icon";

const FindBranchButton = styled(Button).attrs({
  gravityType: "ghost",
  className: "grv-color--interaction-green-50 grv-margin__right--large-2",
})`
  border-color: var(--grv-color-interaction-green-50);
`;

const FatalErrorPageTemplate = ({
  defaultContent,
  content,
  enableButtons = false,
  pageTitle = "Fatal Error",
  showCEMPSurvey = false,
}) => {
  usePageTitle(pageTitle);
  const headingRef = useFocusHeading();
  const readContent = getContentReader(content || defaultContent);
  const findBranchButtonId = useButtonId("findBranch");
  const restartAppButtonId = useButtonId("restartApplication");

  return (
    <Page wrapChildrenInContainer={false} hideVoiceNumber>
      <Grid>
        <ContentContainer>
          <CenteredRow>
            <Col lg={8}>
              <Icon $large src={settings} />
            </Col>
          </CenteredRow>
          <CenteredRow>
            <Col lg={8}>
              <h1
                className="grv-text__heading--xlarge grv-margin__top--medium-2 grv-margin__bottom--medium-2"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: readContent("headerText") }}
                ref={headingRef}
                tabIndex="-1"
              />
            </Col>
          </CenteredRow>
          <CenteredRow className="grv-margin__bottom--medium-1">
            <Col lg={8}>
              <span
                className="grv-text grv-text__heading--medium-large"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: readContent("bodyText") }}
              />
            </Col>
          </CenteredRow>
          {enableButtons && (
            <Row className="grv-row--lg-center">
              <div className="grv-col--lg-8">
                <FindBranchButton
                  id={findBranchButtonId}
                  onClick={() => window.open(readContent("branchLinkTarget"), "_self")}
                >
                  {readContent("branchLinkText")}
                </FindBranchButton>
                <Button
                  id={restartAppButtonId}
                  onClick={() => window.open(readContent("buttonLinkTarget"), "_self")}
                  gravityType="action"
                >
                  {readContent("buttonText")}
                </Button>
              </div>
            </Row>
          )}
        </ContentContainer>
      </Grid>
      {showCEMPSurvey && <CEMPSurvey />}
    </Page>
  );
};

export default FatalErrorPageTemplate;
