import styled from "styled-components";

const ExternalLink = styled.a.attrs({
  "aria-label": "", // setting the empty label is the correct accessibility behavior for links with text content
  role: "link",
  tabIndex: "0",
  target: "_blank",
  rel: "noopener noreferrer",
  /* May change to 40 post-gravity update, for now match progress button */
  className: "grv-background--transparent grv-color--interaction-blue-50",
})`
  text-decoration: underline;
  cursor: pointer;
`;

export default ExternalLink;
